import { EdgeType, TreeGraph, TreeGraphLink, TreeGraphNode } from 'types/backend/response/TreeGraph';
import _ from 'lodash';

export enum EdgeDirection {
    INBOUND,
    OUTBOUND,
}

export function getAdjacentEdgesOfType(
    graph: TreeGraph,
    nodeId: string,
    direction: EdgeDirection,
    edgeType: EdgeType = EdgeType.SEQ_SUCCESSOR
): TreeGraphLink[] {
    if (direction === EdgeDirection.INBOUND) {
        return graph.links.filter((e) => e.edgeType === edgeType && e.target === nodeId);
    } else {
        return graph.links.filter((e) => e.edgeType === edgeType && e.source === nodeId);
    }
}

export function getNodeAncestors(graph: TreeGraph, nodeId: string): string[] {
    const inEdges = getAdjacentEdgesOfType(graph, nodeId, EdgeDirection.INBOUND);

    const inEdgeDescendants = inEdges.map((inEdge) => [inEdge.source, ...getNodeAncestors(graph, inEdge.source)]);
    return _.flatten(inEdgeDescendants);
}

export function getNodeDescendants(graph: TreeGraph, nodeId: string): string[] {
    const outEdges = getAdjacentEdgesOfType(graph, nodeId, EdgeDirection.OUTBOUND);

    const outEdgeDescendants = outEdges.map((inEdge) => [inEdge.target, ...getNodeDescendants(graph, inEdge.target)]);
    return _.flatten(outEdgeDescendants);
}

export function getNode(graph: TreeGraph, nodeId: string): TreeGraphNode {
    return graph.nodes.find((n) => n.id === nodeId) as TreeGraphNode;
}

export function getSequenceUpToNode(graph: TreeGraph, nodeId: string) {
    const sequenceNodeIds = [...getNodeAncestors(graph, nodeId).reverse(), nodeId];
    const sequenceNodes = sequenceNodeIds.map((id) => getNode(graph, id));
    return sequenceNodes.map((n) => n.nodeSequence).join('');
}
