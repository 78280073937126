import React from 'react';
import { WordList } from 'data/wordlists';

export type StatefulWordList = WordList & {
    active: boolean;
    color?: string;
};

export interface IWordListContext {
    wordLists: StatefulWordList[];
    activeWordLists: StatefulWordList[];
    toggleWordList: (wordListName: string) => void;
}

const defaultContext: IWordListContext = {
    wordLists: [],
    activeWordLists: [],
    toggleWordList: () => {
        // Initial value. Replaced by context provider.
    },
};

const WordListContext = React.createContext(defaultContext);

export default WordListContext;
