import {
    reTrainResultDecoder,
    successMessageDecoder,
    treeCatalogDecoder,
    treeGraphDecoder,
    treeInitDecoder,
} from 'backend/json-decoders';
import { TreeGraph } from 'types/backend/response/TreeGraph';
import { TreePredictPayload } from 'types/backend/request/TreePredictPayload';
import { TreeCatalog } from 'types/backend/response/TreeCatalog';
import { SuccessMessage } from 'types/backend/response/SuccessMessage';
import { ModelReTrainPayload } from 'types/backend/request/ModelReTrainPayload';
import { JSONValue } from 'types/common/JSONValue';
import { ReTrainResult } from 'types/backend/response/ReTrainResult';
import { TreeReplacePredictAndGetPayload } from 'types/backend/request/TreeReplacePredictAndGetPayload';
import { JsonDecoder } from 'ts.data.json';

enum ResponseType {
    JSON,
    BLOB,
    TEXT,
}

class BackendQueryEngine {
    // THE FOLLOWING LINE IS AUTOMATICALLY CHANGED IN DOCKER DEPLOYMENT CONFIGURATION
    private static readonly BASE_URL = 'https://0a47cbcf.nlg.generaitor.dbvis.de';

    private static async queryBackend(
        route: string,
        parameters: JSONValue = {},
        responseType: ResponseType = ResponseType.JSON,
        method: 'POST' | 'GET' = 'POST'
    ): Promise<unknown> {
        const requestURL = `${BackendQueryEngine.BASE_URL}/${route}`;

        return fetch(requestURL, {
            body: method === 'POST' ? JSON.stringify(parameters) : undefined,
            headers: {
                'Content-Type': 'application/json',
            },
            method,
        }).then((response) => {
            if (responseType === ResponseType.JSON) return response.json();
            if (responseType === ResponseType.BLOB) return response.blob();
            if (responseType === ResponseType.TEXT) return response.text();

            return null;
        });
    }

    public static async treesList(): Promise<TreeCatalog> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`trees`);
        return treeCatalogDecoder.decodeToPromise(jsonResponse);
    }

    public static async treeInit(startingSequence: string): Promise<string> {
        const payload = {
            startingSequence,
        };

        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`tree/init`, payload);

        const parsedJsonResponse = await treeInitDecoder.decodeToPromise(jsonResponse);
        return parsedJsonResponse.treeId;
    }

    public static async treeDelete(treeId: string): Promise<SuccessMessage> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`tree/${treeId}/delete`);
        return successMessageDecoder.decodeToPromise(jsonResponse);
    }

    public static async treeGet(treeId: string): Promise<TreeGraph> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`tree/${treeId}/get`);

        return treeGraphDecoder.decodeToPromise(jsonResponse).catch((e) => {
            successMessageDecoder.decodeToPromise(jsonResponse).then((sm) => console.error(sm));
            throw e;
        });
    }

    // START: METHODS FROM GENERATIVE WORKSPACE
    public static async treePredict(treeId: string, parameters?: TreePredictPayload): Promise<SuccessMessage> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`tree/${treeId}/predict`, parameters);

        return successMessageDecoder.decodeToPromise(jsonResponse);
    }

    public static async treeNodePrune(treeId: string, nodeId: string): Promise<SuccessMessage> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`tree/${treeId}/prune`, { nodeId });

        return successMessageDecoder.decodeToPromise(jsonResponse);
    }

    public static async treeNodeEdit(treeId: string, nodeId: string, nodeSequence: string): Promise<SuccessMessage> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`tree/${treeId}/edit`, {
            nodeId,
            nodeSequence,
        });

        return successMessageDecoder.decodeToPromise(jsonResponse);
    }

    public static async treeNodeMerge(treeId: string, startNodeId: string, endNodeId: string): Promise<SuccessMessage> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`tree/${treeId}/merge`, {
            startNodeId,
            endNodeId,
        });

        return successMessageDecoder.decodeToPromise(jsonResponse);
    }

    public static async treeNodeAutoMerge(treeId: string): Promise<SuccessMessage> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`tree/${treeId}/auto-merge`);
        return successMessageDecoder.decodeToPromise(jsonResponse);
    }
    // END: METHODS FROM GENERATIVE WORKSPACE

    // START: METHODS FROM COMPARATIVE WORKSPACE
    public static async treeReplacePredictAndGet(
        treeId: string,
        payload: TreeReplacePredictAndGetPayload
    ): Promise<TreeGraph> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`tree/${treeId}/predict-and-get`, payload);

        return treeGraphDecoder.decodeToPromise(jsonResponse).catch((e) => {
            successMessageDecoder.decodeToPromise(jsonResponse).then((sm) => console.error(sm));
            throw e;
        });
    }
    // END: METHODS FROM COMPARATIVE WORKSPACE

    public static async getModelName(): Promise<string> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(
            `model/get-name`,
            undefined,
            ResponseType.JSON,
            'GET'
        );
        return JsonDecoder.string.decodeToPromise(jsonResponse);
    }

    public static async modelReTrain(sessionId: string, payload: ModelReTrainPayload): Promise<ReTrainResult> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`model/retrain/${sessionId}`, payload);
        return reTrainResultDecoder.decodeToPromise(jsonResponse);
    }

    public static async modelReset(sessionId: string): Promise<SuccessMessage> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`model/reset/${sessionId}`);
        return successMessageDecoder.decodeToPromise(jsonResponse);
    }
}

export default BackendQueryEngine;
