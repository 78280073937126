import React, { FunctionComponent, PropsWithChildren, useCallback } from 'react';
import WordListContext from 'App/WordListContext';
import { produce } from 'immer';
import _ from 'lodash';
import { WordList } from 'data/wordlists';

interface Props {
    allWordLists: WordList[];
}

const CMAP = ['#BDCCE4', '#E4BDCC', '#E4D5BD', '#D1D1D1', '#BDE4D5'];
// const CMAP = ['grey', 'grey', 'grey', 'grey', 'grey', 'grey', 'grey'];

const WordListContextProvider: FunctionComponent<PropsWithChildren<Props>> = ({ children, allWordLists }) => {
    const [activeWordLists, setActiveWordLists] = React.useState<{ id: string; colorIdx: number }[]>([]);

    const toggleWordList = useCallback(
        (id: string) => {
            setActiveWordLists((curr) =>
                // If the word list is already active, remove it from the list of active word lists.
                // Otherwise, add it to the list of active word lists. When doing this, assign the first
                // free colorIdx to the word list.
                produce(curr, (draft) => {
                    const wordListIdx = draft.findIndex((wordList) => wordList.id === id);
                    if (wordListIdx === -1) {
                        if (draft.length >= CMAP.length) {
                            console.error('Maximum number of active word lists reached.');
                            return;
                        }
                        const freeColorIdx =
                            _.range(0, allWordLists.length).find(
                                (colorIdx) => !curr.some((wordList) => wordList.colorIdx === colorIdx)
                            ) ?? 0;
                        draft.push({ id, colorIdx: freeColorIdx });
                    } else {
                        draft.splice(wordListIdx, 1);
                    }
                })
            );
        },
        [allWordLists.length]
    );

    const wordListsWithState = React.useMemo(
        () =>
            // Assign a color from schemeTableau10 to each active word list using the colorIdx.
            // Also, add a boolean flag to each word list indicating whether it is active or not.
            allWordLists.map((wordList) => {
                const activeWordList = activeWordLists.find((activeWordList) => activeWordList.id === wordList.id);
                return {
                    ...wordList,
                    active: activeWordList !== undefined,
                    color: activeWordList !== undefined ? CMAP[activeWordList.colorIdx] : undefined,
                };
            }),
        [allWordLists, activeWordLists]
    );

    const activeWordListsWithState = React.useMemo(
        () => wordListsWithState.filter((wordList) => wordList.active),
        [wordListsWithState]
    );

    return (
        <WordListContext.Provider
            value={{ wordLists: wordListsWithState, activeWordLists: activeWordListsWithState, toggleWordList }}
        >
            {children}
        </WordListContext.Provider>
    );
};

export default WordListContextProvider;
