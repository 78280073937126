export enum TreeDetail {
    'FULL' = 'Full',
    'COLLAPSED' = 'Collapsed',
    'SEMI_COLLAPSED' = 'Semi-Collapsed',
}

export const TREE_DISPLAY_STYLES = Object.values(TreeDetail);

// Define a function to get the next enum value, given the current value.
export function next(current: TreeDetail): TreeDetail {
    const currentIndex = TREE_DISPLAY_STYLES.indexOf(current);
    const nextIndex = (currentIndex + 1) % TREE_DISPLAY_STYLES.length;
    return TREE_DISPLAY_STYLES[nextIndex];
}
