import React, { PropsWithChildren } from 'react';
import TreeContext from 'App/TreeContext';
import { TreeGraph } from 'types/backend/response/TreeGraph';

interface Props {
    treeGraph: TreeGraph;
}

const TreeContextProvider = ({ children, treeGraph }: PropsWithChildren<Props>) => {
    return (
        <TreeContext.Provider
            value={{
                treeGraph,
            }}
        >
            {children}
        </TreeContext.Provider>
    );
};

export default TreeContextProvider;
