import React from 'react';
import { StatefulWordList } from 'App/WordListContext';
import { TreeGraph, TreeGraphNode } from 'types/backend/response/TreeGraph';

export interface OccurringNode {
    treeGraph: TreeGraph;
    treeGraphNode: TreeGraphNode;
}

export type OccurringWord = {
    word: string;
    nodes: OccurringNode[];
};
export type WordListHit = { wordList: StatefulWordList; occurringWords: OccurringWord[] };

export interface IWordListHitsContext {
    wordListHits: WordListHit[];
    getWordListsInNode: (nodeId: string) => StatefulWordList[];
}

const defaultContext: IWordListHitsContext = {
    wordListHits: [],
    getWordListsInNode: () => {
        // Initial value. Replaced by context provider.
        return [];
    },
};

const WordListHitsContext = React.createContext(defaultContext);

export default WordListHitsContext;
