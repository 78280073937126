import { TreeDetail } from 'types/common/TreeDetail';

export interface TreeDisplayStyle {
    treeDetail: TreeDetail;
    nodeEmbeddingColoring: boolean;
    nodeProbabilityColoring: boolean;
    edgeSentimentColoring: boolean;
    wordListColoring: boolean;
}

export const INITIAL_TREE_DISPLAY_STYLE = {
    treeDetail: TreeDetail.FULL,
    nodeEmbeddingColoring: true,
    nodeProbabilityColoring: true,
    edgeSentimentColoring: true,
    wordListColoring: true,
};
