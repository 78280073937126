import React, { useReducer } from 'react';
import TreeDisplayStyleContext from 'App/TreeDisplayStyleContext';
import { next } from 'types/common/TreeDetail';
import { INITIAL_TREE_DISPLAY_STYLE, TreeDisplayStyle } from 'types/common/TreeDisplayStyle';

export interface TreeDisplayStyleAction {
    type: keyof TreeDisplayStyle;
}

function reducer(state: TreeDisplayStyle, action: TreeDisplayStyleAction) {
    switch (action.type) {
        case 'treeDetail':
            return { ...state, treeDetail: next(state.treeDetail) };
        case 'nodeEmbeddingColoring':
            return { ...state, nodeEmbeddingColoring: !state.nodeEmbeddingColoring };
        case 'nodeProbabilityColoring':
            return { ...state, nodeProbabilityColoring: !state.nodeProbabilityColoring };
        case 'edgeSentimentColoring':
            return { ...state, edgeSentimentColoring: !state.edgeSentimentColoring };
        case 'wordListColoring':
            return { ...state, wordListColoring: !state.wordListColoring };
        default:
            throw new Error();
    }
}

const TreeDisplayStyleContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [treeDisplayStyle, treeDisplayStyleDispatch] = useReducer(reducer, INITIAL_TREE_DISPLAY_STYLE);

    return (
        <TreeDisplayStyleContext.Provider value={{ treeDisplayStyle, treeDisplayStyleDispatch }}>
            {children}
        </TreeDisplayStyleContext.Provider>
    );
};

export default TreeDisplayStyleContextProvider;
