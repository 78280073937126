import React, { FunctionComponent } from 'react';
import ColorMapChooser from 'App/EmbeddingMapVis/ColorMapChooser';
import ColorMapVis from 'App/EmbeddingMapVis/ColorMapVis';

interface Props {}

const EmbeddingMapVis: FunctionComponent<Props> = (props) => {
    return (
        <>
            <ColorMapChooser />
            <ColorMapVis />
        </>
    );
};

export default EmbeddingMapVis;
