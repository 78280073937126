import React, { useContext, useEffect, useMemo } from 'react';
import { TreeGraphLink, TreeGraphNode } from 'types/backend/response/TreeGraph';
import { layout } from 'App/TreeVis/layout';
import dagre, { GraphEdge } from 'dagre';
import NodeComponent from 'App/TreeVis/Graph/NodeComponent/NodeComponent';
import { produce } from 'immer';
import EdgeComponent from 'App/TreeVis/Graph/EdgeComponent';
import TreeContext from 'App/TreeContext';
import NodeHighlightContext from '../NodeHighlightContext';
import { TreeDetail } from 'types/common/TreeDetail';
import TreeDisplayStyleContext from 'App/TreeDisplayStyleContext';

export type GraphLayoutChangeHandler = (graphBBox: DOMRect, baseNodeBBox: DOMRect) => void;

interface Props {
    x: number;
    y: number;
    onLayoutChange: GraphLayoutChangeHandler;
}

const TreeGraphComponent: React.FunctionComponent<Props> = ({ x, y, onLayoutChange }) => {
    const [nodeSizes, setNodeSizes] = React.useState<Record<string, { width: number; height: number }>>({});

    const { treeDisplayStyle: displayStyle } = useContext(TreeDisplayStyleContext);
    const { treeGraph } = useContext(TreeContext);
    const { subTreeGraph } = useContext(NodeHighlightContext);

    const g: dagre.graphlib.Graph<TreeGraphNode> = React.useMemo(() => {
        const nodesep = displayStyle.treeDetail === TreeDetail.FULL ? 30 : 10;
        const ranksep = displayStyle.treeDetail === TreeDetail.FULL ? 40 : 15;

        return layout(x, y, treeGraph, nodeSizes, nodesep, ranksep);
    }, [displayStyle, x, y, treeGraph, nodeSizes]);

    useEffect(() => {
        const rootNode = g.node(treeGraph.graph.rootNodeId);
        const rootNodeBBox = new DOMRect(
            rootNode.x - rootNode.width / 2,
            rootNode.y - rootNode.height / 2,
            rootNode.width,
            rootNode.height
        );
        const graphBBox = new DOMRect(x, y, g.graph().width ?? 0, g.graph().height ?? 0);

        onLayoutChange(graphBBox, rootNodeBBox);
    }, [g, onLayoutChange, treeGraph.graph.rootNodeId, x, y]);

    // const treeGraphNodes = useMemo(() => treeGraph.nodes, [treeGraph]);
    const subTreeNodeIds = subTreeGraph?.nodes.map((n) => n.id);

    // Memoize those handlers, so they do not lead to infinite re-renders
    // when doing the iterative bottom-up size estimation.
    const nodeSizeChangeHandlers = useMemo(
        () =>
            treeGraph.nodes.map((n) => (newSize: { width: number; height: number }) => {
                setNodeSizes((prevNodeSizes) => {
                    return produce(prevNodeSizes, (draftNodeSizes) => {
                        draftNodeSizes[n.id] = newSize;
                    });
                });
            }),
        [treeGraph.nodes]
    );

    const nodeElements: JSX.Element[] = treeGraph.nodes.map((n, idx) => {
        const node: dagre.Node & TreeGraphNode = g.node(n.id);

        const nodeLeft = node.x - node.width / 2;
        const nodeTop = node.y - node.height / 2;

        return (
            <NodeComponent
                treeGraph={treeGraph}
                treeGraphNode={node}
                key={n.id}
                x={nodeLeft}
                y={nodeTop}
                width={node.width}
                height={node.height}
                onSizeChange={nodeSizeChangeHandlers[idx]}
                displayStyle={displayStyle.treeDetail}
            />
        );
    });

    const linkElements: JSX.Element[] = g.edges().map((e, idx) => {
        const sourceNode = g.node(e.v);
        const targetNode = g.node(e.w);

        return (
            <EdgeComponent
                edge={g.edge(e) as GraphEdge & TreeGraphLink}
                treeGraphSourceNode={sourceNode}
                treeGraphTargetNode={targetNode}
                belongsToHoveredBranch={subTreeNodeIds?.includes(e.w) ?? false}
                key={`${e.v}_${e.w}`}
            />
        );
    });

    return (
        <>
            {linkElements}
            {nodeElements}
        </>
    );
};

export default TreeGraphComponent;
