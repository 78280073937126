import React, { FunctionComponent, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { COLOR_MAPS, ColorMap } from 'lib/color2d/types';
import ColorMapContext from 'App/ColorMapContext';

interface Props {}

const ColorMapChooser: FunctionComponent<Props> = () => {
    const { colorMap, setColorMap } = React.useContext(ColorMapContext);

    const onColorMapChangeHandler = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            setColorMap(e.target.value as ColorMap);
        },
        [setColorMap]
    );

    return (
        <>
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Selected Color Map:</Form.Label>
                    <Form.Select onChange={onColorMapChangeHandler} defaultValue={colorMap}>
                        {COLOR_MAPS.map((cm) => (
                            <option key={cm}>{cm}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Form>
        </>
    );
};

export default ColorMapChooser;
