import React from 'react';
import { INITIAL_TREE_DISPLAY_STYLE, TreeDisplayStyle } from 'types/common/TreeDisplayStyle';
import { TreeDisplayStyleAction } from 'App/TreeDisplayStyleContextProvider';

export interface ITreeDisplayStyleContext {
    treeDisplayStyle: TreeDisplayStyle;
    treeDisplayStyleDispatch: React.Dispatch<TreeDisplayStyleAction>;
}

const defaultContext: ITreeDisplayStyleContext = {
    treeDisplayStyle: INITIAL_TREE_DISPLAY_STYLE,
    treeDisplayStyleDispatch: () => {
        // Initial value. Replaced by context provider.
    },
};

const TreeDisplayStyleContext = React.createContext(defaultContext);

export default TreeDisplayStyleContext;
