import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import NodeText from 'App/TreeVis/Graph/NodeComponent/NodeText';
import { INIT_SIZE, Size } from 'types/common/Size';
import { PropsWithSizeUpdater } from 'types/PropsWithSizeUpdater';

const AnnotationText = styled.text`
    text-anchor: middle;
    fill: var(--bs-primary);
    font-weight: bold;
    font-size: 12px;
`;

interface Props {
    x: number;
    y: number;
    text: string;
    padding?: number;
}
const BaseNode: FunctionComponent<PropsWithSizeUpdater<Props>> = ({ x, y, text, onSizeChange, padding = 10 }) => {
    const [size, setSize] = React.useState<Size>(INIT_SIZE);

    const onSizeChangeHandler = useCallback(
        (newSize: Size) => {
            const nodeSize = { width: newSize.width + 2 * padding, height: newSize.height + 2 * padding };
            setSize(nodeSize);
            onSizeChange && onSizeChange(nodeSize);
        },
        [onSizeChange, padding]
    );

    return (
        <g>
            <AnnotationText
                filter="url(#solidWhiteBackground)"
                x={x + size.width / 2}
                y={y - 6}
                style={{ fill: 'var(--bs-primary)' }}
            >
                BASE
            </AnnotationText>
            <rect
                rx={5}
                x={x}
                y={y}
                width={size.width}
                height={size.height}
                style={{
                    stroke: 'white',
                    fill: 'white',
                    filter: 'drop-shadow( 2px 2px 2px var(--bs-gray))',
                    strokeWidth: 3,
                }}
            />
            <rect
                rx={5}
                x={x}
                y={y}
                width={size.width}
                height={size.height}
                style={{
                    stroke: 'var(--bs-gray)',
                    fill: 'var(--bs-gray)',
                    fillOpacity: 0.2,
                    strokeWidth: 3,
                }}
            />
            <NodeText
                x={x}
                y={y}
                padding={padding}
                text={text}
                onSizeChange={onSizeChangeHandler}
                highlightWords={['<PH>']}
            />
        </g>
    );
};

export default BaseNode;
