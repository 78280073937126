import React, { FunctionComponent, useCallback } from 'react';
import { UpsetListRootNode } from 'types/common/UpsetData';
import WordListElement from 'App/TreeVis/UpsetVis/WordListElement';
import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { BiMinusCircle, BiPlusCircle } from 'react-icons/bi';

const StyledListGroupItem = styled(ListGroup.Item)`
    // Prevent all line wrapping.
    white-space: nowrap;
    font-weight: bold;

    // Make all children non-bold.
    & > * {
        font-weight: normal;
    }
`;

interface Props {
    node: UpsetListRootNode;
    widthUpsetIntersect: number;

    initialCollapsed?: boolean;
}

const WordListGroup: FunctionComponent<Props> = ({ node, widthUpsetIntersect, initialCollapsed }) => {
    const [isCollapsed, setIsCollapsed] = React.useState(initialCollapsed ?? false);

    const childrenWithNodeColor = node.children.map((d) => ({ ...d, color: node.color }));
    const childrenWithChildCount = childrenWithNodeColor.map((d, i) => ({ ...d, count: node.countChildren[i] }));

    const expandedCollapsedIcon = isCollapsed ? (
        <BiPlusCircle size={12} style={{ marginBottom: 12 }} />
    ) : (
        <BiMinusCircle size={12} style={{ marginBottom: 12 }} />
    );

    const onClickHandler = useCallback(() => {
        setIsCollapsed((prev) => !prev);
    }, []);

    if (childrenWithChildCount.length > 0) {
        return (
            <StyledListGroupItem style={{ color: node.color }} onClick={onClickHandler}>
                {node.symbol}&ensp;
                {node.label}
                {expandedCollapsedIcon}
                {!isCollapsed && (
                    <ListGroup style={{ overflowY: 'auto', maxHeight: 200, marginTop: 2 }} variant="flush">
                        {childrenWithChildCount.map((n, idx) => (
                            <WordListElement node={n} key={idx} />
                        ))}
                    </ListGroup>
                )}
            </StyledListGroupItem>
        );
    }

    return null;
};

export default WordListGroup;
