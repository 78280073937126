import React from 'react';
import TreeVisContent from 'App/TreeVis/TreeVisContent';
import VisSVG from 'App/VisSVG/VisSVG';
import NodeHighlightContext from 'App/NodeHighlightContext';
import { TreeGraph } from 'types/backend/response/TreeGraph';

interface Props {
    treeGraphs: TreeGraph[];
}

const TreeVis: React.FunctionComponent<Props> = ({ treeGraphs }) => {
    const { clearNodeHighlight } = React.useContext(NodeHighlightContext);

    const onSVGClick = (e: React.MouseEvent<SVGSVGElement>) => {
        clearNodeHighlight();
        e.stopPropagation();
    };

    return (
        <>
            <VisSVG onClick={onSVGClick}>
                <defs>
                    <filter x="0" y="0" width="1" height="1" id="solidWhiteBackground">
                        <feFlood floodColor="white" result="bg" />
                        <feMerge>
                            <feMergeNode in="bg" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
                <TreeVisContent treeGraphs={treeGraphs} />
            </VisSVG>
        </>
    );
};

export default TreeVis;
