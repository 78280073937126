import React, { FunctionComponent, useCallback, useContext } from 'react';
import { TreeGraph, TreeGraphNode } from 'types/backend/response/TreeGraph';
import { Size } from 'types/common/Size';
import styled from 'styled-components';
import NodeHighlightContext, { INodeHighlightContext } from 'App/NodeHighlightContext';
import TreeConfigContext, { ITreeConfigContext } from 'App/TreeConfigContext';
import NodeText from 'App/TreeVis/Graph/NodeComponent/NodeText';
import NodeBox from 'App/TreeVis/Graph/NodeComponent/NodeBox';
import { TreeDetail } from 'types/common/TreeDetail';
import NodeWordlistSymbols from 'App/TreeVis/Graph/NodeComponent/NodeWordlistSymbols';
import WordListHitsContext from 'App/WordListHitsContext';
import { StatefulWordList } from 'App/WordListContext';
import TreeDisplayStyleContext from 'App/TreeDisplayStyleContext';

const AnnotationText = styled.text`
    text-anchor: middle;
    fill: var(--bs-primary);
    font-weight: bold;
    font-size: 12px;
`;

interface Props {
    treeGraph: TreeGraph;
    treeGraphNode: TreeGraphNode;
    x: number;
    y: number;
    width: number;
    height: number;
    padding?: number;
    displayStyle?: TreeDetail;
    onSizeChange?: (newSize: Size) => void;
}

const NodeComponent: React.FunctionComponent<Props> = (props) => {
    const { reloadTree } = useContext(TreeConfigContext);
    const { highlightNode, clearNodeHighlight } = useContext(NodeHighlightContext);
    const { getWordListsInNode } = useContext(WordListHitsContext);

    const wordListsInNode = getWordListsInNode(props.treeGraphNode.id);

    return (
        <NodeComponentWithContextValues
            reloadTree={reloadTree}
            highlightNode={highlightNode}
            clearNodeHighlight={clearNodeHighlight}
            wordListsInNode={wordListsInNode}
            {...props}
        />
    );
};

interface NodeComponentWithContextValues extends Props {
    reloadTree: ITreeConfigContext['reloadTree'];
    highlightNode: INodeHighlightContext['highlightNode'];
    clearNodeHighlight: INodeHighlightContext['clearNodeHighlight'];
    wordListsInNode: StatefulWordList[];
}

const _NodeComponentWithContextValues: FunctionComponent<NodeComponentWithContextValues> = ({
    x,
    y,
    width,
    height,
    treeGraph,
    treeGraphNode,
    onSizeChange,
    padding = 10,
    highlightNode,
    clearNodeHighlight,
    wordListsInNode,
    displayStyle = TreeDetail.FULL,
}) => {
    const treeDetail = useContext(TreeDisplayStyleContext).treeDisplayStyle.treeDetail;

    const onMouseOver = useCallback(
        (e: React.MouseEvent<SVGGElement>) => {
            highlightNode(treeGraph, treeGraphNode.id);
        },
        [highlightNode, treeGraph, treeGraphNode.id]
    );

    const onMouseLeave = useCallback(
        (e: React.MouseEvent<SVGGElement>) => {
            clearNodeHighlight();
        },
        [clearNodeHighlight]
    );

    const onTextSizeChangeHandler = useCallback(
        (newSize: Size) => {
            const nodeSize = { width: newSize.width + 2 * padding, height: newSize.height + 2 * padding };
            onSizeChange?.(nodeSize);
        },
        [onSizeChange, padding]
    );

    const nodeText =
        displayStyle === TreeDetail.FULL || (wordListsInNode.length > 0 && treeDetail === TreeDetail.SEMI_COLLAPSED)
            ? treeGraphNode.nodeSequence
            : '';
    // const { setSvgContainerRef, SvgPortal } = useSvgPortal();

    const onNodeClick = useCallback(() => {
        // Copy the beam sequence to the clipboard.
        navigator.clipboard.writeText(treeGraphNode.beamSequence);
    }, [treeGraphNode.beamSequence]);

    return (
        <g
            // ref={(r) => {
            //     setSvgContainerRef(r?.ownerSVGElement ?? null);
            // }}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            style={{ cursor: 'pointer' }}
            onClick={onNodeClick}
        >
            {treeGraphNode.isHead && (
                <AnnotationText x={x + width / 2} y={y - 6} style={{ fill: 'var(--bs-primary)' }}>
                    HEAD
                </AnnotationText>
            )}
            <NodeBox x={x} y={y} width={width} height={height} treeGraphNode={treeGraphNode} />
            <NodeWordlistSymbols wordListsInNode={wordListsInNode} treeGraphNode={treeGraphNode} x={x + 2} y={y - 12} />
            <NodeText
                x={x}
                y={y}
                padding={padding}
                text={nodeText}
                highlightWords={
                    treeGraphNode.id === treeGraph.graph.rootNodeId && treeGraph.graph.ontologyNodeContent
                        ? [treeGraph.graph.ontologyNodeContent]
                        : undefined
                }
                onSizeChange={onTextSizeChangeHandler}
            />
            {/*{isNodeSelected(treeGraphNode.id) && (*/}
            {/*    <SvgPortal>*/}
            {/*        <NodeActionTooltip*/}
            {/*            x={x + width + 10}*/}
            {/*            y={y + height / 2}*/}
            {/*            treeGraphNode={treeGraphNode}*/}
            {/*            selectedNodes={selectedNodes}*/}
            {/*            selectedTree={treeId}*/}
            {/*        />*/}
            {/*    </SvgPortal>*/}
            {/*)}*/}
        </g>
    );
};

const NodeComponentWithContextValues = React.memo(_NodeComponentWithContextValues);

export default NodeComponent;
