import _ from 'lodash';
import { Point2D } from 'types/common/Point2D';

export function getOverallBBox(bBoxes: DOMRect[]) {
    const minXPos = _.min(bBoxes.map((r) => r.x)) ?? 0;
    const minYPos = _.min(bBoxes.map((r) => r.y)) ?? 0;
    const maxXPos = _.max(bBoxes.map((r) => r.x + r.width)) ?? 0;
    const maxYPos = _.max(bBoxes.map((r) => r.y + r.height)) ?? 0;

    return new DOMRect(minXPos, minYPos, maxXPos - minXPos, maxYPos - minYPos);
}

export function getBBoxCenter(bBox: DOMRect): Point2D {
    return { x: bBox.x + bBox.width / 2, y: bBox.y + bBox.height / 2 };
}
