import React, { FunctionComponent } from 'react';
import { useEmbeddingColor } from 'hooks/useEmbeddingColor';
import useSvgPortal from 'App/VisSVG/use-svg-portal';
import { useHover } from 'react-use';
import styled from 'styled-components';
import { KeywordInfoWithPosition } from 'App/EmbeddingMapVis/types/KeywordInfo';

const StyledKwText = styled.text`
    font-size: 12px;
    pointer-events: none;
    dominant-baseline: ideographic;
    text-anchor: start;
`;

interface Props {
    keywordInfo: KeywordInfoWithPosition;
}

const KeywordDot: FunctionComponent<Props> = ({ keywordInfo }) => {
    const kwColor = useEmbeddingColor(keywordInfo.keyword.embedding2d);
    const colorLight = kwColor.brighter(2).formatRgb();
    const colorDark = kwColor.darker(2).formatRgb();

    const { x, y } = keywordInfo;

    const { setSvgContainerRef, SvgPortal } = useSvgPortal();

    const element = (hovered: boolean) => (
        <g>
            <circle cx={x} cy={y} r={6} fill={colorLight} stroke={colorDark} />
            <StyledKwText x={x + 5} y={y - 5} fill={colorLight}>
                {keywordInfo.keyword.keyword}
            </StyledKwText>
        </g>
    );

    const [hoverable, hovered] = useHover(element);

    return (
        <g
            ref={(r) => {
                setSvgContainerRef(r?.ownerSVGElement ?? null);
            }}
        >
            {hoverable}
            <SvgPortal>
                {hovered && (
                    <StyledKwText x={x + 6} y={y - 6} fill={colorDark}>
                        <tspan x={x + 6} dy={-12}>
                            {keywordInfo.keyword.embedding2d.map((d) => d.toFixed(2)).join(', ')}
                        </tspan>
                        <tspan x={x + 6} dy={-12}>
                            &#34;{keywordInfo.nodeSequence}&#34;
                        </tspan>
                    </StyledKwText>
                )}
            </SvgPortal>
        </g>
    );
};

export default KeywordDot;
