import React from 'react';
import PredictionParametersContext from 'App/PredictionParametersContext';

const PredictionParametersContextProvider = ({ children }: { children: React.ReactNode }) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats
    const [topK, setTopK] = React.useState<number>(2);
    const [nWords, setNWords] = React.useState<number>(10);
    const [temperature, setTemperature] = React.useState<number | null>(null);

    return (
        <PredictionParametersContext.Provider
            value={{
                topK,
                nWords,
                temperature,
                setTopK,
                setNWords,
                setTemperature,
            }}
        >
            {children}
        </PredictionParametersContext.Provider>
    );
};

export default PredictionParametersContextProvider;
