import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import * as ReactDOM from 'react-dom';

export type SvgPortalProps = PropsWithChildren<{
    offsetX?: number;
    offsetY?: number;
}>;

export type UseSvgPortalResult = {
    setSvgContainerRef: (element: SVGSVGElement | null) => void;
    SvgPortal: React.FC<SvgPortalProps>;
};

export default function useSvgPortal(): UseSvgPortalResult {
    const [svgContainerRef, setSvgContainerRef] = React.useState<SVGSVGElement | null>(null);

    const svgPortalGroup = useMemo(() => svgContainerRef?.getElementById('svg-portal-group'), [svgContainerRef]);

    const SvgPortal = useCallback(
        ({ children, offsetX = 0, offsetY = 0 }: PropsWithChildren<SvgPortalProps>) => {
            if (svgPortalGroup) {
                return ReactDOM.createPortal(
                    <g transform={`translate(${offsetX}, ${offsetY})`}>{children}</g>,
                    svgPortalGroup
                );
            } else {
                return null;
            }
        },
        [svgPortalGroup]
    );

    return {
        setSvgContainerRef,
        SvgPortal,
    };
}
