import React from 'react';
import { DEFAULT_TREE_GRAPH, TreeGraph } from 'types/backend/response/TreeGraph';

export interface ITreeContext {
    treeGraph: TreeGraph;
}

const defaultContext: ITreeContext = {
    treeGraph: DEFAULT_TREE_GRAPH,
};

const TreeContext = React.createContext(defaultContext);

export default TreeContext;
