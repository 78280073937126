import React, { FunctionComponent } from 'react';
import { outlineSpecialCharsAndWrapText } from 'tools/text-processing';
import { PropsWithSizeUpdater } from 'types/PropsWithSizeUpdater';
import useSvgElementBBox from 'hooks/useSvgElementBBox';

interface Props {
    x: number;
    y: number;
    padding: number;
    text: string;
    highlightWords?: string[];
}

const NodeText: FunctionComponent<PropsWithSizeUpdater<Props>> = ({
    x,
    y,
    padding,
    text,
    onSizeChange,
    highlightWords,
}) => {
    const [textRef, bbox] = useSvgElementBBox<SVGTextElement>([text]);

    React.useEffect(() => {
        onSizeChange && onSizeChange(bbox);
    }, [onSizeChange, bbox]);

    // dominantBaseline: 'alphabetic' causes the text to be shifted up by 13px. This is a workaround.
    const shiftedY = y + 15;

    const escapedText = outlineSpecialCharsAndWrapText(text, x + padding, shiftedY + padding, 19, 22, highlightWords);

    return (
        <text
            ref={textRef}
            x={x + padding}
            y={shiftedY + padding}
            style={{
                dominantBaseline: 'alphabetic',
                alignmentBaseline: 'baseline',
            }}
        >
            {escapedText}
        </text>
    );
};

export default NodeText;
