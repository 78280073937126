import React from 'react';
import App from 'App/App';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/flatly/bootstrap.min.css';
import './index.css';
import { createRoot } from 'react-dom/client';

if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
        exclude: [/^Portal/],
    });
}

const maybeRootElement = document.getElementById('root');
if (maybeRootElement != null) {
    const root = createRoot(maybeRootElement);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}
