import _ from 'lodash';
import { rgb, RGBColor } from 'd3-color';

export function bounds(array: Array<number>): { min: number; max: number } {
    return array.reduce(({ min, max }, item) => ({ min: Math.min(item, min), max: Math.max(item, max) }), {
        min: Infinity,
        max: -Infinity,
    });
}

export function filterObject<T extends string | number | symbol, U>(
    o: Record<T, U>,
    filterFn: (key: T, value: U) => boolean
): Record<T, U> {
    const result = {} as Record<T, U>;

    let key: T;
    for (key in o) {
        const value = o[key];

        if (filterFn(key, value)) {
            result[key] = value;
        }
    }

    return result;
}

export function getRandomRGBColor(): RGBColor {
    return rgb(_.random(0, 255), _.random(0, 255), _.random(0, 255));
}

export function doIntervalsOverlap(a: [number, number], b: [number, number]): boolean {
    return a[0] <= b[1] && b[0] <= a[1];
}

export function groupBy3Key<
    T,
    U extends keyof T,
    V extends keyof T[U],
    W extends keyof T[U][V],
    X extends T[U][V][W] & (number | string)
>(xs: T[], key1: U, key2: V, key3: W): T[][] {
    const grouped = xs.reduce((rv: Record<string, T[]>, x: T) => {
        const key = x[key1][key2][key3] as number | string;
        (rv[key] = rv[key] || []).push(x);
        return rv;
    }, {} as Record<X, T[]>);

    return Object.values(grouped);
}
