import { DependencyList, useMemo } from 'react';
import { mean } from 'd3-array';
import { useEmbeddingColor } from 'hooks/useEmbeddingColor';
import { Keyword } from 'types/backend/response/TreeGraph';
import { rgb, RGBColor } from 'd3-color';

export function useAvgKeywordEmbeddingColor(
    keywords?: Keyword[],
    defaultColor: RGBColor = rgb(0, 0, 0, 0),
    deps: DependencyList = []
): RGBColor {
    const memoizedEmbeddings = useMemo(() => {
        return keywords?.map((keyword) => keyword.embedding2d);
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps

    const meanEmbedding: [number, number] | undefined = useMemo(
        () =>
            memoizedEmbeddings !== undefined && memoizedEmbeddings.length > 0
                ? [mean(memoizedEmbeddings.map((e) => e[0])) ?? 0, mean(memoizedEmbeddings.map((e) => e[1])) ?? 0]
                : undefined,
        [memoizedEmbeddings]
    );

    return useEmbeddingColor(meanEmbedding, defaultColor, [memoizedEmbeddings]);
}
