import styled from 'styled-components';
import { Button, Col, ListGroup } from 'react-bootstrap';

export const StyledCol = styled(Col)`
    padding: 0 5px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
`;

export const StyledButton = styled(Button)`
    width: 100%;
`;

export const StyledItemActionButton = styled(Button)`
    padding: 3px;
    margin: 0;
`;

export const StyledListGroupItem = styled(ListGroup.Item)`
    padding: 6px;
`;
