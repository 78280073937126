import React, { FunctionComponent } from 'react';
import PredictionParametersContext from 'App/PredictionParametersContext';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const GridContainer = styled.div`
    display: grid;
    gap: 10px 10px;
    grid-template-columns: auto minmax(0, 1fr) 70px;

    & > * {
        // Center content vertically in grid items.
        align-self: center;
    }
`;

const GridItemLabel = styled.div`
    grid-column-start: 1;
    grid-column-end: 1;
    justify-self: right;
`;

const GridItemSlider = styled.div`
    grid-column-start: 2;
    grid-column-end: 2;
`;

const GridItemValue = styled.div`
    grid-column-start: 3;
    grid-column-end: 3;
`;

const MathParameterSpan = styled.span`
    font-weight: bold;
    font-style: italic;
`;

interface Props {}

const PredictionParametersPanel: FunctionComponent<Props> = (props) => {
    const { topK, nWords, setTopK, setNWords } = React.useContext(PredictionParametersContext);

    const [tmpTopK, setTmpTopK] = React.useState<string>(topK.toFixed(0));
    const [tmpNWords, setTmpNWords] = React.useState<string>(nWords.toFixed(0));

    const onTopKChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => setTmpTopK(e.target.value);
    const onNWordsChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => setTmpNWords(e.target.value);
    const [isElementFocused, setIsElementFocused] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!isElementFocused) {
            // No element is focused => validate input
            let tmpTopKNum, tmpNWordsNum;

            if (!isNaN((tmpTopKNum = parseInt(tmpTopK)))) {
                setTopK(tmpTopKNum);
                setTmpTopK(tmpTopKNum.toFixed(0));
            } else {
                setTmpTopK(topK.toFixed(0));
            }

            if (!isNaN((tmpNWordsNum = parseInt(tmpNWords)))) {
                setNWords(tmpNWordsNum);
                setTmpNWords(tmpNWordsNum.toFixed(0));
            } else {
                setTmpNWords(nWords.toFixed(0));
            }
        }
    }, [isElementFocused, tmpTopK, tmpNWords, setTopK, topK, setNWords, nWords]);

    return (
        <>
            <Form.Group>
                <GridContainer>
                    <GridItemLabel>
                        Top&#8209;
                        <MathParameterSpan>k</MathParameterSpan>
                    </GridItemLabel>
                    <GridItemSlider>
                        <Form.Control
                            type="range"
                            min="1"
                            max="5"
                            step={1}
                            value={tmpTopK}
                            onChange={onTopKChangeHandler}
                            onMouseDown={() => setIsElementFocused(true)}
                            onMouseUp={() => setIsElementFocused(false)}
                        />
                    </GridItemSlider>
                    <GridItemValue>
                        <Form.Control
                            type="text"
                            value={tmpTopK}
                            onChange={onTopKChangeHandler}
                            onFocus={() => setIsElementFocused(true)}
                            onBlur={() => setIsElementFocused(false)}
                        />
                    </GridItemValue>
                    <GridItemLabel>
                        Next&nbsp;
                        <MathParameterSpan>n</MathParameterSpan>
                        &nbsp;Words:
                    </GridItemLabel>
                    <GridItemSlider>
                        <Form.Control
                            type="range"
                            min="5"
                            max="100"
                            step={5}
                            value={tmpNWords}
                            onChange={onNWordsChangeHandler}
                            onMouseDown={() => setIsElementFocused(true)}
                            onMouseUp={() => setIsElementFocused(false)}
                        />
                    </GridItemSlider>
                    <GridItemValue>
                        <Form.Control
                            type="text"
                            value={tmpNWords}
                            onChange={onNWordsChangeHandler}
                            onFocus={() => setIsElementFocused(true)}
                            onBlur={() => setIsElementFocused(false)}
                        />
                    </GridItemValue>
                </GridContainer>
            </Form.Group>
        </>
    );
};

export default PredictionParametersPanel;
