import React, { FunctionComponent, useContext, useMemo } from 'react';
import ColorMapContext from 'App/ColorMapContext';
import Color2D from 'lib/color2d/color2d';
import TreeContext from 'App/TreeContext';
import KeywordDot from 'App/EmbeddingMapVis/KeywordDot';
import NodeHighlightContext, { INodeHighlightContext } from 'App/NodeHighlightContext';
import TreeContextProvider from 'App/TreeContextProvider';
import { KeywordInfo, KeywordInfoWithPosition } from 'App/EmbeddingMapVis/types/KeywordInfo';
import KeywordBeams from 'App/EmbeddingMapVis/KeywordBeams';

interface Props {}

const ColorMapVis: FunctionComponent<Props> = (props) => {
    const { color2D } = React.useContext(ColorMapContext);
    return color2D ? <ColorMapVisWithColor2D color2D={color2D} /> : null;
};

const ColorMapVisWithColor2D: FunctionComponent<{ color2D: Color2D }> = ({ color2D }) => {
    const { treeGraph } = useContext(TreeContext);
    const { highlightedNode, subTreeGraph } = useContext(NodeHighlightContext);

    return (
        <TreeContextProvider treeGraph={subTreeGraph ?? treeGraph}>
            <ColorMapVisContent color2D={color2D} highlightedNode={highlightedNode} width={300} height={300} />
        </TreeContextProvider>
    );
};

interface ContentProps {
    color2D: Color2D;
    highlightedNode: INodeHighlightContext['highlightedNode'];
    width: number;
    height: number;
}

const ColorMapVisContent: FunctionComponent<ContentProps> = ({ color2D, width, height, highlightedNode }) => {
    const { treeGraph } = useContext(TreeContext);

    const keywords: KeywordInfo[] = useMemo(
        () =>
            treeGraph.nodes.flatMap((n) =>
                n.keywords
                    .map((kw, idx) => ({
                        kwIdx: n.id + '_' + idx,
                        nodeId: n.id,
                        nodeSequence: n.nodeSequence,
                        keyword: kw,
                    }))
                    .sort((a, b) => a.keyword.position - b.keyword.position)
            ),
        [treeGraph.nodes]
    );

    // Attach the 2D position on the vis.
    const keywordsWithPosition: KeywordInfoWithPosition[] = useMemo(
        () =>
            keywords.map((kw) => ({
                ...kw,
                x: color2D.toTargetRangeX(kw.keyword.embedding2d[0], [0, 1]) * width,
                y: color2D.toTargetRangeY(kw.keyword.embedding2d[1], [0, 1]) * height,
            })),
        [color2D, height, keywords, width]
    );

    return (
        <svg width={width} height={height}>
            <image
                width={width}
                height={height}
                href={'data:image/png;base64,' + color2D.getBase64EncodedPngImageData()}
            />
            {highlightedNode && <KeywordBeams keywordInfos={keywordsWithPosition} />}
            {keywordsWithPosition.map((kwInfo) => (
                <KeywordDot key={kwInfo.kwIdx} keywordInfo={kwInfo} />
            ))}
        </svg>
    );
};

export default ColorMapVis;
