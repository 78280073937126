import React, {
    FunctionComponent,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Point2D } from 'types/common/Point2D';
import { line } from '@visx/shape';
import { curveBasis } from '@visx/curve';
import { EdgeType, TreeGraphLink, TreeGraphNode } from 'types/backend/response/TreeGraph';
import { GraphEdge } from 'dagre';
import TreeDisplayStyleContext from 'App/TreeDisplayStyleContext';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';
import { TreeDetail } from 'types/common/TreeDetail';

const EdgeLabelText = styled.text`
    dominant-baseline: central;
    text-anchor: middle;
    font-size: 12px;
`;

const EdgeLabelBgRect = styled.rect`
    fill: var(--bs-white);
    fill-opacity: 0.6;
`;

function positionsToPathString(positions: Point2D[]): string {
    const path = line<Point2D>()
        .x((d) => d.x)
        .y((d) => d.y)
        .curve(curveBasis);

    const pathString = path(positions);

    return pathString !== null ? pathString : '';
}

function getPointAtRelativePosition(svgPath: SVGPathElement, position: number): DOMPoint | undefined {
    // If the SVG path is not drawn yet, the exception
    // "SVGGeometryElement.getPointAtLength: No path available for measuring"
    // is thrown.
    try {
        const length = svgPath.getTotalLength();
        return svgPath.getPointAtLength(length * position);
    } catch (e) {
        // This happens quite frequently and is fixed anyway when re-rendering after path initialization.
        // Therefore, we just ignore this exception and return undefined.
        return undefined;
    }
}

interface Props {
    edge: GraphEdge & TreeGraphLink;
    treeGraphSourceNode: TreeGraphNode;
    treeGraphTargetNode: TreeGraphNode;
    belongsToHoveredBranch: boolean;
}

const EdgeComponent: FunctionComponent<Props> = (props) => {
    const { treeDisplayStyle } = useContext(TreeDisplayStyleContext);

    return (
        <EdgeComponentWithTreeStyle
            {...props}
            edgeSentimentColoring={treeDisplayStyle.edgeSentimentColoring}
            treeDetail={treeDisplayStyle.treeDetail}
        />
    );
};

type EdgeComponentWithTreeStyleProps = Props & {
    edgeSentimentColoring: boolean;
    treeDetail: TreeDetail;
};

const EdgeComponentWithTreeStyle: FunctionComponent<EdgeComponentWithTreeStyleProps> = ({
    edge,
    treeGraphSourceNode,
    treeGraphTargetNode,
    belongsToHoveredBranch,
    edgeSentimentColoring,
    treeDetail,
}) => {
    const [pathRef, setPathRef] = useState<SVGPathElement | null>(null);
    const [probabilityLabelPosition, setProbabilityLabelPosition] = useState<Point2D | undefined>(undefined);

    const pathString = positionsToPathString(edge.points);

    const sentimentEdgeElement = useMemo(() => {
        if (edgeSentimentColoring) {
            const sourceSentiment = treeGraphSourceNode.sentiment;
            let sentimentColor = 'var(--bs-primary)';
            if (sourceSentiment === undefined) sentimentColor = 'var(--bs-primary)';
            else if (sourceSentiment.label === 'positive') sentimentColor = 'var(--bs-green)';
            else if (sourceSentiment.label === 'negative') sentimentColor = 'var(--bs-red)';

            return (
                <path
                    d={pathString}
                    style={{
                        fill: 'none',
                        strokeDasharray: edge.edgeType === EdgeType.SEQ_SUCCESSOR ? undefined : 2,
                        strokeWidth: 30,
                        opacity: 0.2,
                        strokeLinecap: 'round',
                        stroke: sentimentColor,
                    }}
                />
            );
        }

        return null;
    }, [edge.edgeType, edgeSentimentColoring, pathString, treeGraphSourceNode.sentiment]);

    const edgeColor = belongsToHoveredBranch ? 'var(--bs-info)' : 'var(--bs-gray-500)';

    const [measureRef, { width, height, x, y }] = useMeasure();

    // Create a new ref callback which updates the standard ref and calls the measureRef callback.
    const refCallback = useCallback(
        (node: SVGPathElement) => {
            setPathRef(node);
            measureRef(node);
        },
        [measureRef]
    );

    // Update the label position when the path re-renders.
    useEffect(() => {
        if (!pathRef) return;
        setProbabilityLabelPosition(getPointAtRelativePosition(pathRef, 0.5));

        // The timeout makes sure that the label position is updated after the path has been rendered.
        const timeout = setTimeout(() => {
            setProbabilityLabelPosition(getPointAtRelativePosition(pathRef, 0.5));
        }, 1000);

        return () => clearTimeout(timeout);
    }, [pathRef, width, height, x, y]);

    return (
        <>
            {sentimentEdgeElement}
            <path
                ref={refCallback}
                d={pathString}
                style={{
                    fill: 'none',
                    strokeDasharray: edge.edgeType === EdgeType.SEQ_SUCCESSOR ? undefined : 2,
                    strokeWidth: Math.max(treeGraphTargetNode.nodeProbability * 10, 0.5),
                    stroke: edgeColor,
                }}
            />
            {treeDetail == TreeDetail.FULL && probabilityLabelPosition && (
                <EdgeLabel x={probabilityLabelPosition.x} y={probabilityLabelPosition.y}>
                    <tspan>{treeGraphTargetNode.nodeProbability.toFixed(2)}</tspan>
                </EdgeLabel>
            )}
        </>
    );
};

interface EdgeLabelProps {
    x: number;
    y: number;
}

const EdgeLabel: React.FunctionComponent<PropsWithChildren<EdgeLabelProps>> = ({ x, y, children }) => {
    const [textRef, { width, height }] = useMeasure();

    return (
        <>
            <EdgeLabelBgRect
                rx={5}
                x={(x ?? 0) - width / 2 - 1}
                y={(y ?? 0) - height / 2}
                width={width + 2}
                height={height}
            />
            <EdgeLabelText ref={textRef} x={x} y={y}>
                {children}
            </EdgeLabelText>
        </>
    );
};

export default EdgeComponent;
