import { err, JsonDecoder, ok } from 'ts.data.json';
import {
    EdgeType,
    Keyword,
    Sentiment,
    Token,
    TreeGraph,
    TreeGraphLink,
    TreeGraphNode,
    TreeGraphProperties,
} from 'types/backend/response/TreeGraph';
import { TreeInit } from 'types/backend/response/TreeInit';
import { $JsonDecoderErrors } from 'ts.data.json/dist/json-decoder';
import { TreeInfo, TreeStatistics } from 'types/backend/response/TreeCatalog';
import { SuccessMessage } from 'types/backend/response/SuccessMessage';
import { EpochDetails } from 'types/backend/response/ReTrainResult';

/* ******
 * Date *
 ****** */
const dateDecoder: JsonDecoder.Decoder<Date> = new JsonDecoder.Decoder<Date>((json: unknown) => {
    if (typeof json === 'string') {
        const isValidDate = !isNaN(Date.parse(json));

        if (isValidDate) {
            return ok<Date>(new Date(json));
        }
    }

    return err<Date>($JsonDecoderErrors.primitiveError(json, 'Date'));
});

/* ****************
 * SuccessMessage *
 **************** */
export const successMessageDecoder = JsonDecoder.object<SuccessMessage>(
    {
        success: JsonDecoder.boolean,
        errorMessage: JsonDecoder.optional<string>(JsonDecoder.string),
        traceback: JsonDecoder.optional<string>(JsonDecoder.string),
    },
    'SuccessMessage'
);

/* **********
 * TreeInit *
 ********** */
export const treeInitDecoder = JsonDecoder.object<TreeInit>(
    {
        treeId: JsonDecoder.string,
    },
    'TreeInit'
);

/* ***********
 * TreeGraph *
 *********** */
const edgeTypeDecoder = JsonDecoder.enumeration<EdgeType>(EdgeType, 'EdgeType');

export const numberTuple2dDecoder = JsonDecoder.tuple([JsonDecoder.number, JsonDecoder.number], '[number, number]');

export const numberTuple3dDecoder = JsonDecoder.tuple(
    [JsonDecoder.number, JsonDecoder.number, JsonDecoder.number],
    '[number, number, number]'
);

const keywordDecoder = JsonDecoder.object<Keyword>(
    {
        leafNodeId: JsonDecoder.string,
        keyword: JsonDecoder.string,
        score: JsonDecoder.number,
        position: JsonDecoder.number,
        embedding2d: numberTuple2dDecoder,
        // embedding3d: numberTuple3dDecoder,
    },
    'Keyword'
);

const tokenDecoder = JsonDecoder.object<Token>(
    {
        string: JsonDecoder.string,
        token: JsonDecoder.string,
        tokenInputId: JsonDecoder.number,
    },
    'Token'
);

const tokenArrayDecoder = JsonDecoder.array<Token>(tokenDecoder, 'Token[]');

const treeGraphPropertiesDecoder = JsonDecoder.object<TreeGraphProperties>(
    {
        creationDate: dateDecoder,
        rootNodeId: JsonDecoder.string,
        treeId: JsonDecoder.string,
        startingSequence: JsonDecoder.string,
        ontologyNodeHash: JsonDecoder.optional(JsonDecoder.string),
        ontologyNodeContent: JsonDecoder.optional(JsonDecoder.string),
    },
    'TreeGraphProperties'
);

const sentimentDecoder = JsonDecoder.object<Sentiment>(
    {
        label: JsonDecoder.string,
        score: JsonDecoder.number,
    },
    'Sentiment'
);

const treeGraphNodeDecoder = JsonDecoder.object<TreeGraphNode>(
    {
        id: JsonDecoder.string,
        parentId: JsonDecoder.optional(JsonDecoder.string),
        nodeProbability: JsonDecoder.number,
        beamProbability: JsonDecoder.number,
        nodeSequence: JsonDecoder.string,
        beamSequence: JsonDecoder.string,
        keywords: JsonDecoder.failover([], JsonDecoder.array<Keyword>(keywordDecoder, 'KeywordArrayDecoder')),
        isLeaf: JsonDecoder.boolean,
        isHead: JsonDecoder.boolean,
        step: JsonDecoder.number,
        tokens: tokenArrayDecoder,
        startPos: JsonDecoder.number,
        endPos: JsonDecoder.number,
        avgKeywordEmbedding2d: JsonDecoder.optional(numberTuple2dDecoder),
        sentiment: JsonDecoder.optional(sentimentDecoder),
        // avgKeywordEmbedding3d: JsonDecoder.optional(numberTuple3dDecoder),
    },
    'TreeGraphNode'
);

const treeGraphLinkDecoder = JsonDecoder.object<TreeGraphLink>(
    {
        source: JsonDecoder.string,
        target: JsonDecoder.string,
        edgeType: edgeTypeDecoder,
    },
    'TreeGraphLink'
);

export const treeGraphDecoder = JsonDecoder.object<TreeGraph>(
    {
        directed: JsonDecoder.boolean,
        graph: treeGraphPropertiesDecoder,
        nodes: JsonDecoder.array<TreeGraphNode>(treeGraphNodeDecoder, 'TreeGraphNode[]'),
        links: JsonDecoder.array<TreeGraphLink>(treeGraphLinkDecoder, 'TreeGraphLink[]'),
    },
    'TreeGraph'
);

/* *************
 * TreeCatalog *
 ************* */
const treeStatisticsDecoder = JsonDecoder.object<TreeStatistics>(
    {
        highestStep: JsonDecoder.number,
        numEdges: JsonDecoder.number,
        numNodes: JsonDecoder.number,
    },
    'TreeStatistics'
);

const treeInfoDecoder = JsonDecoder.combine(treeGraphPropertiesDecoder, treeStatisticsDecoder);

export const treeCatalogDecoder = JsonDecoder.dictionary<TreeInfo>(treeInfoDecoder, 'TreeCatalog');

/* ***************
 * ReTrainResult *
 *************** */
const epochDetailsDecoder = JsonDecoder.object<EpochDetails>(
    {
        epoch: JsonDecoder.number,
        train_loss: JsonDecoder.number,
        train_runtime: JsonDecoder.number,
        train_samples_per_second: JsonDecoder.number,
        train_steps_per_second: JsonDecoder.number,
    },
    'EpochDetails'
);

export const reTrainResultDecoder = JsonDecoder.tuple(
    [JsonDecoder.number, JsonDecoder.number, epochDetailsDecoder],
    'ReTrainResult'
);
