import React, { RefObject, useRef } from 'react';
import { Zoom } from '@visx/zoom';
import styled from 'styled-components';
import { ProvidedZoom } from '@visx/zoom/lib/types';
import { ZoomState } from 'types/ZoomState';
import { useMeasure } from 'react-use';

const VisContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const OverflowPreventingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
`;

const INITIAL_TRANSFORM = {
    scaleX: 1,
    scaleY: 1,
    translateX: -10000,
    translateY: -10000,
    skewX: 0,
    skewY: 0,
};

const SCALE_PARAMS = {
    scaleXMin: 1 / 64,
    scaleXMax: 32,
    scaleYMin: 1 / 64,
    scaleYMax: 32,
};

interface Props {
    onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
}

const VisSVG: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    children,
    onClick,
}: React.PropsWithChildren<Props>) => {
    const [isDragging, setIsDragging] = React.useState<boolean>(false);
    const [hasDragged, setHasDragged] = React.useState<boolean>(false);
    // const { treeGraph } = useContext(TreeContext);

    const contentGroupRef = useRef<SVGGElement>(null);
    const zoomRef = React.useRef<(ProvidedZoom<SVGSVGElement> & ZoomState) | null>(null);
    const svgRef = React.useRef<SVGSVGElement>(null);
    const [visContainerRef, { width, height }] = useMeasure();

    // // Center the contentGroup in the viewport when
    // //   (1) window is resized or
    // //   (2) treeGraph changes.
    // React.useEffect(() => {
    //     if (contentGroupRef.current && zoomRef.current) {
    //         const contentGroupBBox = contentGroupRef.current.getBBox();
    //
    //         const zoomFactor = Math.min(1, Math.min(width / contentGroupBBox.width, height / contentGroupBBox.height));
    //
    //         zoomRef.current?.setTransformMatrix({
    //             ...INITIAL_TRANSFORM,
    //             scaleX: zoomFactor,
    //             scaleY: zoomFactor,
    //             translateX: width / 2 - (contentGroupBBox.x + contentGroupBBox.width / 2) * zoomFactor,
    //             translateY: height / 2 - (contentGroupBBox.y + contentGroupBBox.height / 2) * zoomFactor,
    //         });
    //     }
    // }, [height, treeGraph, width]);

    return (
        <OverflowPreventingContainer>
            <VisContainer ref={visContainerRef as unknown as RefObject<HTMLDivElement>}>
                <Zoom<SVGSVGElement>
                    width={width}
                    height={height}
                    {...SCALE_PARAMS}
                    initialTransformMatrix={INITIAL_TRANSFORM}
                >
                    {(zoom) => {
                        zoomRef.current = zoom;

                        const onDragStartHandler = (e: React.MouseEvent<SVGSVGElement>) => {
                            if (e.target === svgRef.current) {
                                setIsDragging(true);
                                zoom.dragStart(e);
                            }
                        };

                        const onDragHandler = (e: React.MouseEvent<SVGSVGElement>) => {
                            if (isDragging) {
                                setHasDragged(true);
                            }

                            if (isDragging) {
                                zoom.dragMove(e);
                            }
                        };

                        const onDragEndHandler = (e: React.MouseEvent<SVGSVGElement>) => {
                            setIsDragging(false);
                            zoom.dragEnd();
                        };

                        const onClickHandler = (e: React.MouseEvent<SVGSVGElement>) => {
                            if (!hasDragged) {
                                onClick?.(e);
                            }
                            setHasDragged(false);
                        };

                        const onWheelHandler = (e: React.WheelEvent<SVGSVGElement>) => {
                            if (e.target === svgRef.current) {
                                zoom.handleWheel(e);
                            }
                        };

                        return (
                            <svg
                                width={width}
                                height={height}
                                onMouseDown={onDragStartHandler}
                                onMouseMove={onDragHandler}
                                onMouseUp={onDragEndHandler}
                                onWheel={onWheelHandler}
                                className={'no-select'}
                                style={{ touchAction: 'none' }}
                                onClick={onClickHandler}
                                // ref={zoom.containerRef}
                                ref={svgRef}
                            >
                                <g ref={contentGroupRef} transform={zoom.toString()} style={{ border: 'none' }}>
                                    <svg
                                        width={20000}
                                        height={20000}
                                        viewBox={'-10000 -10000 20000 20000'}
                                        style={{ border: 'none' }}
                                    >
                                        <g>{children}</g>
                                        <g id={'svg-portal-group'}></g>
                                    </svg>
                                </g>
                            </svg>
                        );
                    }}
                </Zoom>
            </VisContainer>
        </OverflowPreventingContainer>
    );
};

export default VisSVG;
