import React, { FunctionComponent, useContext } from 'react';
import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import WordListContext from 'App/WordListContext';
import _ from 'lodash';

const StyledListGroup = styled(ListGroup)`
    cursor: pointer;
`;

const Wrapper = styled.div`
    & > * {
        margin-bottom: 20px;
    }

    & > :last-child {
        margin-bottom: 0;
    }
`;

interface Props {}

const WordListPanel: FunctionComponent<Props> = (props) => {
    const { wordLists, toggleWordList } = useContext(WordListContext);

    const wordListsSorted = _.sortBy(wordLists, (wordList) => (wordList.active ? 0 : 1));

    return (
        <Wrapper>
            <StyledListGroup style={{ overflowY: 'scroll', maxHeight: 300 }}>
                {wordListsSorted.map((wordList) => {
                    const onClickHandler = () => toggleWordList(wordList.id);

                    return (
                        <ListGroup.Item
                            className={'no-select'}
                            key={wordList.id}
                            onClick={onClickHandler}
                            style={{ backgroundColor: wordList.color ?? 'white' }}
                        >
                            {wordList.symbol}&ensp;{wordList.name}
                        </ListGroup.Item>
                    );
                })}
            </StyledListGroup>
        </Wrapper>
    );
};

export default WordListPanel;
