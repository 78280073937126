import React from 'react';
import Color2D from 'lib/color2d/color2d';
import { ColorMap } from 'lib/color2d/types';

interface IColorMapContext {
    colorMap: ColorMap;
    color2D?: Color2D;
    setColorMap: (colorMap: ColorMap) => void;
}

const defaultContext: IColorMapContext = {
    colorMap: ColorMap.BREMM,
    color2D: undefined,
    setColorMap: () => {
        // Initial value. Replaced by context provider.
    },
};

const ColorMapContext = React.createContext(defaultContext);

export default ColorMapContext;
