import React, { FunctionComponent, useMemo, useState } from 'react';
import { StatefulWordList } from 'App/WordListContext';
import { TreeGraphNode } from 'types/backend/response/TreeGraph';
import { Size } from 'types/common/Size';
import { PropsWithSizeUpdater } from 'types/PropsWithSizeUpdater';
import useSvgElementBBox from 'hooks/useSvgElementBBox';
import { produce } from 'immer';
import { darker } from 'tools/colors';

interface Props {
    x: number;
    y: number;
    wordListsInNode: StatefulWordList[];
    treeGraphNode: TreeGraphNode;
}

type ContentProps = Props & {
    wordListsInNode: StatefulWordList[];
};

const NodeWordlistSymbols: FunctionComponent<ContentProps> = ({ x, y, wordListsInNode }) => {
    const [symbolSizes, setSymbolSizes] = useState<Size[]>([]);

    // Memoize those handlers, so they do not lead to infinite re-renders
    // when doing the iterative bottom-up size estimation.
    const onSizeChangeHandlers = useMemo(
        () =>
            wordListsInNode.map((g, idx) => (newSize: Size) => {
                setSymbolSizes((prev) => {
                    return produce(prev, (draftSizes) => {
                        draftSizes[idx] = newSize;
                    });
                });
            }),
        [wordListsInNode]
    );

    let xPos = x;
    const symbolElements = wordListsInNode.map((wlh, idx) => {
        const symbolElement = (
            <NodeWordListSymbol key={idx} x={xPos} y={y} onSizeChange={onSizeChangeHandlers[idx]} wordList={wlh} />
        );
        xPos += symbolSizes[idx]?.width + 3;
        return symbolElement;
    });

    return <>{symbolElements}</>;
};

interface SymbolProps {
    x: number;
    y: number;
    wordList: StatefulWordList;
}

const NodeWordListSymbol: FunctionComponent<PropsWithSizeUpdater<SymbolProps>> = ({ x, y, wordList, onSizeChange }) => {
    const [gRef, bbox] = useSvgElementBBox<SVGSVGElement>([wordList]);

    const actualSize = useMemo(() => {
        return {
            width: bbox.width + 2,
            height: bbox.height + 2,
        };
    }, [bbox.height, bbox.width]);

    React.useEffect(() => {
        onSizeChange && onSizeChange(actualSize);
    }, [onSizeChange, actualSize]);

    return (
        <svg x={x} y={y} width={actualSize.width + 2} height={actualSize.height + 2}>
            <rect
                rx={3}
                x={0}
                y={0}
                width={actualSize.width}
                height={actualSize.height}
                fill={'rgba(255, 255, 255, 0.8)'}
            />
            <g ref={gRef}>
                <rect x={0} y={0} width={1} height={1} style={{ opacity: 0, pointerEvents: 'none' }} />
                <g style={{ transform: 'scale(1.6)', color: darker(wordList.color) }}>{wordList.symbol}</g>
            </g>
        </svg>
    );
};

export default NodeWordlistSymbols;
