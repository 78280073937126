import { DependencyList, LegacyRef, useEffect, useRef, useState } from 'react';

export default function useSvgElementBBox<T extends SVGGraphicsElement>(
    deps?: DependencyList
): [LegacyRef<T>, DOMRect] {
    const svgElementRef = useRef<T>(null);
    const [size, setBBox] = useState(new DOMRect());

    useEffect(() => {
        if (svgElementRef.current) {
            setBBox(svgElementRef.current.getBBox());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps ?? [])]);

    return [svgElementRef, size];
}
