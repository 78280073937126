import React from 'react';
import { TreeGraph } from 'types/backend/response/TreeGraph';

export interface INodeHighlightContext {
    highlightedNode?: [TreeGraph, string];
    subTreeGraph?: TreeGraph;
    highlightNode: (treeGraph: TreeGraph, nodeId: string) => void;
    clearNodeHighlight: () => void;
}

export const defaultContext: INodeHighlightContext = {
    highlightedNode: undefined,
    subTreeGraph: undefined,
    highlightNode: () => {
        // Initial value. Replaced by context provider.
    },
    clearNodeHighlight: () => {
        // Initial value. Replaced by context provider.
    },
};

const NodeHighlightContext = React.createContext(defaultContext);

export default NodeHighlightContext;
