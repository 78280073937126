import React, { FunctionComponent } from 'react';

interface Props {
    baseNodePosition: DOMRect;
    rootNodePosition: DOMRect;
}

const ROUNDED_CORNER_RADIUS = 25;

const BaseNodeEdge: FunctionComponent<Props> = ({ baseNodePosition, rootNodePosition }) => {
    // Create a path from the base node to the root node. The path should go through control points on the same
    // vertical level as the base node and the same horizontal level as the root node.
    const baseNodeCenter = {
        x: baseNodePosition.x + baseNodePosition.width / 2,
        y: baseNodePosition.y + baseNodePosition.height / 2,
    };
    const rootNodeCenter = {
        x: rootNodePosition.x + rootNodePosition.width / 2,
        y: rootNodePosition.y + rootNodePosition.height / 2,
    };

    const roundedCornerCenter = {
        x: baseNodeCenter.x + ROUNDED_CORNER_RADIUS,
        y: rootNodeCenter.y + ROUNDED_CORNER_RADIUS * (rootNodeCenter.y > baseNodeCenter.y ? -1 : 1),
    };
    const data = [
        baseNodeCenter,
        { x: baseNodeCenter.x, y: roundedCornerCenter.y },
        { x: roundedCornerCenter.x, y: rootNodeCenter.y },
        rootNodeCenter,
    ];

    // Create the path data.
    // First, draw a line from data[0] to data[1].
    // Then, draw an arc from data[1] to data[2], with roundedCornerCenter as the center of the arc.
    // Then, draw a line from data[2] to data[3].
    const line = `M ${data[0].x} ${data[0].y} L ${data[1].x} ${
        data[1].y
    } A ${ROUNDED_CORNER_RADIUS} ${ROUNDED_CORNER_RADIUS} 0 0 ${rootNodeCenter.y > baseNodeCenter.y ? 0 : 1} ${
        data[2].x
    } ${data[2].y} L ${data[3].x} ${data[3].y}`;

    return (
        <path
            style={{ pointerEvents: 'none', strokeDasharray: '3' }}
            d={line}
            fill={'none'}
            stroke="var(--bs-gray)"
            strokeWidth={3}
        />
    );
};

export default BaseNodeEdge;
