import React, { FunctionComponent, useContext } from 'react';
import { Container, Form, ListGroup, Row } from 'react-bootstrap';
import { StyledCol, StyledItemActionButton, StyledListGroupItem } from 'App/ConfigPanels/StyledComponents';
import { IoTrashOutline } from 'react-icons/io5';
import TreeConfigContext from 'App/TreeConfigContext';
import { produce } from 'immer';

interface Props {}

const TemplateInstanceSelectionPanel: FunctionComponent<Props> = ({}) => {
    const { ontologyNodeContents, setOntologyNodeContents } = useContext(TreeConfigContext);

    const handleAdd = (value: string) => {
        setOntologyNodeContents((prevState) =>
            produce(prevState, (draft) => {
                draft.push(value);
            })
        );
    };

    const handleRemove = (index: number) => {
        setOntologyNodeContents((prevState) =>
            produce(prevState, (draft) => {
                draft.splice(index, 1);
                return draft;
            })
        );
    };

    return (
        <div className="App">
            <Form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    const value = (e.currentTarget.elements.namedItem('valueInput') as HTMLInputElement).value;
                    (e.currentTarget.elements.namedItem('valueInput') as HTMLInputElement).value = '';
                    handleAdd(value);
                }}
            >
                <Form.Group controlId="valueInput">
                    <Form.Label>
                        Replacements for <strong>&lt;PH&gt;</strong> node:
                    </Form.Label>
                    <Form.Control type="text" placeholder="Enter a value" />
                </Form.Group>
            </Form>
            <ListGroup style={{ marginTop: 12 }}>
                {ontologyNodeContents.map((value: string, index: number) => (
                    <StyledListGroupItem key={index}>
                        <Container fluid>
                            <Row className={'align-content-start align-items-center'}>
                                <StyledCol style={{ flexGrow: 1 }}>{value}</StyledCol>
                                <StyledCol style={{ flexGrow: 0 }}>
                                    <StyledItemActionButton
                                        data-toggle={'tooltip'}
                                        title="Delete Tree"
                                        variant="outline-primary"
                                        onClick={() => handleRemove(index)}
                                        size="sm"
                                    >
                                        <IoTrashOutline size={20} />
                                    </StyledItemActionButton>
                                </StyledCol>
                            </Row>
                        </Container>
                    </StyledListGroupItem>
                ))}
            </ListGroup>
        </div>
    );
};

export default TemplateInstanceSelectionPanel;
