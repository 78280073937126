export type Shape = (number | null)[];

export enum EdgeType {
    SEQ_SUCCESSOR = 'SEQ_SUCCESSOR',
    LOOP = 'LOOP',
}

export interface Token {
    string: string;
    token: string;
    tokenInputId: number;
}

export interface Keyword {
    leafNodeId: string;
    keyword: string;
    score: number;
    position: number;
    embedding2d: [number, number];
    // embedding3d: [number, number, number];
}

export interface TreeGraphProperties {
    creationDate: Date;
    treeId: string;
    rootNodeId: string;
    startingSequence: string;
    ontologyNodeHash?: string;
    ontologyNodeContent?: string;
}

export interface TreeGraphNode {
    id: string;
    parentId?: string;
    nodeProbability: number;
    beamProbability: number;
    nodeSequence: string;
    beamSequence: string;
    keywords: Keyword[];
    isLeaf: boolean;
    isHead: boolean;
    step: number;
    tokens: Token[];
    startPos: number;
    endPos: number;
    avgKeywordEmbedding2d?: [number, number];
    sentiment?: Sentiment;
    // avgKeywordEmbedding3d?: [number, number, number];
}

export interface Sentiment {
    label: string;
    score: number;
}

export interface TreeGraphLink {
    source: string;
    target: string;
    edgeType: EdgeType;
}

export interface TreeGraph {
    directed: boolean;
    graph: TreeGraphProperties;
    nodes: TreeGraphNode[];
    links: TreeGraphLink[];
}

export const DEFAULT_TREE_GRAPH: TreeGraph = {
    directed: true,
    graph: {
        creationDate: new Date(0),
        rootNodeId: '',
        startingSequence: '',
        treeId: '',
    },
    nodes: [],
    links: [],
};
