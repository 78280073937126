import React, { FunctionComponent, useCallback } from 'react';
import { Button, Container, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import {
    StyledButton,
    StyledCol,
    StyledItemActionButton,
    StyledListGroupItem,
} from 'App/ConfigPanels/StyledComponents';
import { PulseLoader } from 'react-spinners';
import BackendQueryEngine from 'backend/BackendQueryEngine';
import { IoReturnDownForwardOutline, IoTrashOutline } from 'react-icons/io5';
import TreeConfigContext from 'App/TreeConfigContext';
import { TreeCatalog } from 'types/backend/response/TreeCatalog';
import { wrapText } from 'tools/text-processing';

interface Props {}

const TreeSelectionPanel: FunctionComponent<Props> = (props) => {
    const { selectTree, optionalTreeGraphs } = React.useContext(TreeConfigContext);

    const currentlyLoadedTree = optionalTreeGraphs?.[0]?.graph.treeId;

    const [treeCatalog, setTreeCatalog] = React.useState<TreeCatalog | null>(null);
    const [showTreeCreationDialog, setShowTreeCreationDialog] = React.useState<boolean>(false);
    const startingSequenceTextAreaRef = React.useRef<HTMLTextAreaElement>(null);

    // Load newly created tree after it has been created in the backend.
    const [targetTree, setTargetTree] = React.useState<string | null>(null);
    React.useEffect(() => {
        if (targetTree !== null && treeCatalog !== null && Object.keys(treeCatalog).includes(targetTree)) {
            selectTree(targetTree);
        }
    }, [treeCatalog, targetTree, selectTree]);

    const loadTrees = useCallback(() => {
        BackendQueryEngine.treesList()
            .then((treeCatalog) => setTreeCatalog(treeCatalog))
            .catch((e) => console.warn(e));
    }, []);

    // Re-load available trees every 3 seconds.
    React.useEffect(() => {
        // Request tree catalog every second if there is none yet; otherwise every 5 seconds.
        const timer = setInterval(loadTrees, treeCatalog === null ? 1000 : 5000);
        return () => clearInterval(timer);
    }, [loadTrees, treeCatalog]);

    const onTreeCreateClickHandler = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        setShowTreeCreationDialog(true);
    }, []);

    const onTreeTextEnteredDialogCancel = useCallback(() => {
        setShowTreeCreationDialog(false);

        if (startingSequenceTextAreaRef.current) {
            startingSequenceTextAreaRef.current.value = '';
        }
    }, []);

    const onTreeTextEnteredDialogConfirm = useCallback(() => {
        setShowTreeCreationDialog(false);

        if (startingSequenceTextAreaRef.current && startingSequenceTextAreaRef.current.value.length > 0) {
            BackendQueryEngine.treeInit(startingSequenceTextAreaRef.current.value).then((treeId) => {
                setTargetTree(treeId);
                loadTrees();
            });
        }
    }, [loadTrees]);

    return (
        <>
            <Form.Group>
                <ListGroup>
                    {Object.entries(treeCatalog ?? {}).length === 0 ? (
                        <StyledListGroupItem style={{ color: 'var(--bs-gray)' }} className={'no-select'}>
                            <Container fluid>
                                <Row className={'align-content-start align-items-center'}>
                                    {treeCatalog === null ? (
                                        <>
                                            <StyledCol>
                                                <span>
                                                    <i>Loading Trees</i>
                                                    &emsp;
                                                    <PulseLoader
                                                        color={'var(--bs-gray)'}
                                                        size={'0.33em'}
                                                        speedMultiplier={0.5}
                                                    />
                                                </span>
                                            </StyledCol>
                                        </>
                                    ) : (
                                        <StyledCol style={{ flexGrow: 1 }}>
                                            <i>No Trees Stored</i>
                                        </StyledCol>
                                    )}
                                </Row>
                            </Container>
                        </StyledListGroupItem>
                    ) : (
                        Object.entries(treeCatalog ?? {})
                            .sort(
                                ([_1, treeInfo1], [_2, treeInfo2]) =>
                                    treeInfo2.creationDate.getTime() - treeInfo1.creationDate.getTime()
                            )
                            .map(([treeId, treeInfo], idx) => {
                                // Replace all '\s' with spaces. Break the string into lines of 30 characters each.
                                const seqCleanedAndWrapped = wrapText(
                                    treeInfo.startingSequence.replace(/\s/g, ' '),
                                    50
                                );

                                const creationDate = treeInfo.creationDate.toLocaleString('de-DE');

                                const treeLoadHandler = () => {
                                    selectTree(treeId);
                                };

                                const treeDeleteHandler = () => {
                                    BackendQueryEngine.treeDelete(treeId).then((s) => loadTrees());
                                };

                                return (
                                    <StyledListGroupItem
                                        key={treeId}
                                        style={{
                                            backgroundColor:
                                                treeId === currentlyLoadedTree
                                                    ? 'var(--bs-gray-500)'
                                                    : 'var(--bs-white)',
                                        }}
                                    >
                                        <Container fluid>
                                            <Row className={'align-content-start align-items-center'}>
                                                <StyledCol style={{ flexGrow: 1, marginRight: 10 }}>
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">{seqCleanedAndWrapped}</div>
                                                        {creationDate.replace(' ', '\u00A0')}
                                                    </div>
                                                </StyledCol>
                                                <StyledCol style={{ flexGrow: 0 }}>
                                                    <StyledItemActionButton
                                                        data-toggle={'tooltip'}
                                                        title="Delete Tree"
                                                        variant="outline-primary"
                                                        onClick={treeDeleteHandler}
                                                        size="sm"
                                                    >
                                                        <IoTrashOutline size={20} />
                                                    </StyledItemActionButton>
                                                </StyledCol>
                                                <StyledCol style={{ flexGrow: 0 }}>
                                                    <StyledItemActionButton
                                                        data-toggle={'tooltip'}
                                                        title="Load Tree"
                                                        variant="outline-primary"
                                                        onClick={treeLoadHandler}
                                                        size="sm"
                                                    >
                                                        <IoReturnDownForwardOutline size={20} />
                                                    </StyledItemActionButton>
                                                </StyledCol>
                                            </Row>
                                        </Container>
                                    </StyledListGroupItem>
                                );
                            })
                    )}
                </ListGroup>
                <StyledButton variant="outline-primary" style={{ marginTop: 10 }} onClick={onTreeCreateClickHandler}>
                    Create New Tree
                </StyledButton>
            </Form.Group>
            <Modal animation={false} show={showTreeCreationDialog} onHide={onTreeTextEnteredDialogCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Tree Creation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Starting Sequence for new Tree:</Form.Label>
                        <Form.Control as="textarea" rows={5} ref={startingSequenceTextAreaRef} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={onTreeTextEnteredDialogCancel}>
                        Cancel
                    </Button>
                    <Button variant="outline-primary" onClick={onTreeTextEnteredDialogConfirm}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TreeSelectionPanel;
