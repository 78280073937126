import React, { PropsWithChildren, useCallback } from 'react';
import TreeConfigContext from 'App/TreeConfigContext';
import * as uuid from 'uuid';
import BackendQueryEngine from 'backend/BackendQueryEngine';
import { TreeGraph } from 'types/backend/response/TreeGraph';

interface Props {
    topK: number;
    nWords: number;
}

const TreeConfigContextProvider = ({ children, topK, nWords }: PropsWithChildren<Props>) => {
    const [targetTreeId, setTargetTreeId] = React.useState<string | null>(null);
    const [ontologyNodeContents, setOntologyNodeContents] = React.useState<string[]>(['John', 'Jessica']);

    // This is an internal dummy state that is used to trigger a re-load when the tree version changes.
    const [treeVersion, setTreeVersion] = React.useState<string>(uuid.v4());

    const [optionalTreeGraphs, setTreeGraphs] = React.useState<TreeGraph[]>();

    const selectTree = useCallback((treeId: string) => {
        setTargetTreeId(treeId);
    }, []);

    const reloadTree = useCallback(() => {
        setTreeVersion(uuid.v4());
    }, []);

    // If one of the dependencies changes, update the tree.
    React.useEffect(() => {
        if (targetTreeId) {
            Promise.all(
                ontologyNodeContents.map((ontologyNodeContent) => {
                    return BackendQueryEngine.treeReplacePredictAndGet(targetTreeId, {
                        ontologyNodeContent,
                        topK,
                        nWords,
                    });
                })
            ).then((treeGraphs) => setTreeGraphs(treeGraphs));
        }
    }, [nWords, ontologyNodeContents, targetTreeId, topK, treeVersion]);

    return (
        <TreeConfigContext.Provider
            value={{
                selectTree,
                reloadTree,
                optionalTreeGraphs,
                ontologyNodeContents,
                setOntologyNodeContents,
            }}
        >
            {children}
        </TreeConfigContext.Provider>
    );
};

export default TreeConfigContextProvider;
