import React, { FunctionComponent, useContext } from 'react';
import { TreeDetail } from 'types/common/TreeDetail';
import TreeDisplayStyleContext from 'App/TreeDisplayStyleContext';
import { RiCheckboxBlankLine, RiCheckboxIndeterminateLine, RiCheckboxLine } from 'react-icons/ri';
import styled from 'styled-components';

const Td1 = styled.td`
    text-align: right;
`;

const Td2 = styled.td`
    text-align: left;
    padding-left: 0.5em;
`;

interface Props {}

const TreeDisplayStyleToggle: FunctionComponent<Props> = () => {
    const { treeDisplayStyle, treeDisplayStyleDispatch } = useContext(TreeDisplayStyleContext);

    const offSymbol = <RiCheckboxBlankLine style={{ marginBottom: 4 }} />;
    const semiOnSymbol = <RiCheckboxIndeterminateLine style={{ marginBottom: 4 }} />;
    const onSymbol = <RiCheckboxLine style={{ marginBottom: 4 }} />;

    return (
        <div style={{ cursor: 'pointer' }}>
            <table>
                <tbody>
                    <tr onClick={() => treeDisplayStyleDispatch({ type: 'treeDetail' })}>
                        <Td1>Tree Detail:</Td1>
                        <Td2>
                            {treeDisplayStyle.treeDetail === TreeDetail.FULL
                                ? onSymbol
                                : treeDisplayStyle.treeDetail === TreeDetail.SEMI_COLLAPSED
                                ? semiOnSymbol
                                : offSymbol}
                            &ensp;
                            {treeDisplayStyle.treeDetail}
                        </Td2>
                    </tr>
                    <tr onClick={() => treeDisplayStyleDispatch({ type: 'nodeEmbeddingColoring' })}>
                        <Td1>Node embedding color:</Td1>
                        <Td2>
                            {treeDisplayStyle.nodeEmbeddingColoring ? onSymbol : offSymbol}&ensp;
                            {treeDisplayStyle.nodeEmbeddingColoring ? 'On' : 'Off'}
                        </Td2>
                    </tr>
                    <tr onClick={() => treeDisplayStyleDispatch({ type: 'nodeProbabilityColoring' })}>
                        <Td1>Node probability color:</Td1>
                        <Td2>
                            {treeDisplayStyle.nodeProbabilityColoring ? onSymbol : offSymbol}&ensp;
                            {treeDisplayStyle.nodeProbabilityColoring ? 'On' : 'Off'}
                        </Td2>
                    </tr>
                    <tr onClick={() => treeDisplayStyleDispatch({ type: 'edgeSentimentColoring' })}>
                        <Td1>Edge sentiment color:</Td1>
                        <Td2>
                            {treeDisplayStyle.edgeSentimentColoring ? onSymbol : offSymbol}&ensp;
                            {treeDisplayStyle.edgeSentimentColoring ? 'On' : 'Off'}
                        </Td2>
                    </tr>
                    <tr onClick={() => treeDisplayStyleDispatch({ type: 'wordListColoring' })}>
                        <Td1>Wordlist colors:</Td1>
                        <Td2>
                            {treeDisplayStyle.wordListColoring ? onSymbol : offSymbol}&ensp;
                            {treeDisplayStyle.wordListColoring ? 'On' : 'Off'}
                        </Td2>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default TreeDisplayStyleToggle;
