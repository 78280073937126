import React, { FunctionComponent } from 'react';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { curveCatmullRom } from '@visx/curve';
import { KeywordInfoWithPosition } from 'App/EmbeddingMapVis/types/KeywordInfo';
import { useAvgKeywordEmbeddingColor } from 'hooks/useAvgKeywordEmbeddingColor';
import _ from 'lodash';

interface Props {
    keywordInfos: KeywordInfoWithPosition[];
}

const KeywordBeams: FunctionComponent<Props> = ({ keywordInfos }: Props) => {
    const keywordInfosGrouped = _.groupBy(keywordInfos, (d) => d.keyword.leafNodeId);

    return (
        <>
            {Object.values(keywordInfosGrouped).map((keywordInfos, idx) => (
                <KeywordBeam key={idx} keywordInfos={keywordInfos} />
            ))}
        </>
    );
};

const KeywordBeam: FunctionComponent<Props> = ({ keywordInfos }: Props) => {
    const kwColor = useAvgKeywordEmbeddingColor(keywordInfos.map((kw) => kw.keyword));
    const beamColor = kwColor.darker(2).formatRgb();

    return (
        <Group>
            <LinePath
                curve={curveCatmullRom.alpha(0.5)}
                data={keywordInfos}
                x={(d) => d.x}
                y={(d) => d.y}
                stroke={beamColor}
                strokeWidth={2}
                strokeOpacity={0.7}
                shapeRendering="geometricPrecision"
            />
        </Group>
    );
};

export default KeywordBeams;
