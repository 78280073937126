import React, { useCallback } from 'react';
import NodeHighlightContext from 'App/NodeHighlightContext';
import { TreeGraph } from 'types/backend/response/TreeGraph';
import { getNodeAncestors, getNodeDescendants } from 'tools/graphlib';

const NodeHighlightContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [nodeId, setNodeId] = React.useState<string | undefined>(undefined);
    const [treeGraph, setTreeGraph] = React.useState<TreeGraph | undefined>(undefined);

    const highlightNode = useCallback((treeGraph: TreeGraph, nodeId: string) => {
        setNodeId(nodeId);
        setTreeGraph(treeGraph);
    }, []);

    const clearNodeHighlight = useCallback(() => {
        setNodeId(undefined);
        setTreeGraph(undefined);
    }, []);

    const subTreeGraph: TreeGraph | undefined = (() => {
        if (nodeId !== undefined && treeGraph !== undefined) {
            const subNodeIds: string[] = [
                ...getNodeAncestors(treeGraph, nodeId),
                nodeId,
                ...getNodeDescendants(treeGraph, nodeId),
            ];

            const subEdges = treeGraph.links.filter(
                (l) => subNodeIds.includes(l.source) && subNodeIds.includes(l.target)
            );

            const subNodes = treeGraph.nodes
                // Filter out nodes that are not in the subgraph.
                .filter((n) => subNodeIds.includes(n.id))
                .map((n) => ({
                    ...n,
                    // For each node, filter out keywords whose leafs are not in the subgraph.
                    keywords: n.keywords.filter((kw) => subNodeIds.includes(kw.leafNodeId)),
                }));

            return {
                ...treeGraph,
                nodes: subNodes,
                links: subEdges,
            };
        }

        return undefined;
    })();

    return (
        <NodeHighlightContext.Provider
            value={{
                highlightedNode: treeGraph !== undefined && nodeId !== undefined ? [treeGraph, nodeId] : undefined,
                highlightNode,
                clearNodeHighlight,
                subTreeGraph,
            }}
        >
            {children}
        </NodeHighlightContext.Provider>
    );
};

export default NodeHighlightContextProvider;
