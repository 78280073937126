import dagre from 'dagre';
import { TreeGraph, TreeGraphNode } from 'types/backend/response/TreeGraph';

export function layout(
    x: number,
    y: number,
    treeGraph: TreeGraph,
    nodeSizes: Record<string, { width: number; height: number }>,
    nodesep = 30,
    ranksep = 60,
    padding = 0
) {
    const gModel = new dagre.graphlib.Graph<TreeGraphNode>({ compound: true })
        // For reference on graph parameters, see:
        // https://github.com/dagrejs/dagre/wiki#configuring-the-layout
        .setGraph({
            rankdir: 'LR',
            nodesep,
            edgesep: nodesep,
            ranksep,
            marginx: padding,
            marginy: padding,
            ranker: 'tight-tree',
        })
        .setDefaultEdgeLabel(() => ({}));

    // Add nodes to graph
    treeGraph.nodes.forEach((n) => {
        const mSize = nodeSizes[n.id];

        gModel.setNode(n.id.toString(), {
            width: mSize ? mSize.width : 0,
            height: mSize ? mSize.height : 0,
            ...n,
        });
    });

    // Add edges to graph
    treeGraph.links.forEach((l) => {
        gModel.setEdge(l.source.toString(), l.target.toString(), {
            ...l,
        });
    });

    if (Object.keys(nodeSizes).length > 0) {
        // Layout nodes (only when node sizes are already known)
        dagre.layout(gModel);
    } else {
        // If node sizes have to be determined first, assign dummy values to increase rendering performance
        gModel
            .nodes()
            .map((nId) => gModel.node(nId))
            .forEach((n) => {
                n.x = 0;
                n.y = 0;
            });
        gModel
            .edges()
            .map((e) => gModel.edge(e))
            .forEach((e) => {
                e.points = [];
            });
    }

    // Move graph to desired position.
    gModel.nodes().forEach((nId) => {
        const node = gModel.node(nId);
        node.x += x;
        node.y += y;
    });
    gModel.edges().forEach((e) => {
        const edge = gModel.edge(e);
        edge.points.forEach((p) => {
            p.x += x;
            p.y += y;
        });
    });

    return gModel;
}
