import React from 'react';

interface IPredictionParametersContext {
    topK: number;
    nWords: number;
    temperature: number | null;
    setTopK: (topK: number) => void;
    setNWords: (nextNWords: number) => void;
    setTemperature: (temperature: number | null) => void;
}

const defaultContext: IPredictionParametersContext = {
    topK: 0,
    nWords: 0,
    temperature: 0,
    setTopK: () => {
        // Initial value. Replaced by context provider.
    },
    setNWords: () => {
        // Initial value. Replaced by context provider.
    },
    setTemperature: () => {
        // Initial value. Replaced by context provider.
    },
};

const PredictionParametersContext = React.createContext(defaultContext);

export default PredictionParametersContext;
