import React from 'react';
import { TreeGraph } from 'types/backend/response/TreeGraph';

export interface ITreeConfigContext {
    selectTree: (treeId: string) => void;
    reloadTree: () => void;
    optionalTreeGraphs?: TreeGraph[];
    ontologyNodeContents: string[];
    setOntologyNodeContents: React.Dispatch<React.SetStateAction<string[]>>;
}

const defaultContext: ITreeConfigContext = {
    optionalTreeGraphs: undefined,
    ontologyNodeContents: [],
    setOntologyNodeContents: () => {
        // Initial value. Replaced by context provider.
    },
    selectTree: () => {
        // Initial value. Replaced by context provider.
    },
    reloadTree: () => {
        // Initial value. Replaced by context provider.
    },
};

const TreeConfigContext = React.createContext(defaultContext);

export default TreeConfigContext;
