import React, { useCallback, useMemo } from 'react';
import { TreeGraph } from 'types/backend/response/TreeGraph';
import { produce } from 'immer';
import { Size } from 'types/common/Size';
import _ from 'lodash';
import BaseNode from 'App/TreeVis/BaseNode';
import TreeContextProvider from 'App/TreeContextProvider';
import TreeGraphComponent, { GraphLayoutChangeHandler } from 'App/TreeVis/TreeGraphComponent';
import BaseNodeEdge from 'App/TreeVis/BaseNodeEdge';
import { getBBoxCenter, getOverallBBox } from 'tools/bbox';
import UpsetVis from 'App/TreeVis/UpsetVis/UpsetVis';

interface Props {
    treeGraphs: TreeGraph[];
}

const VERTICAL_GRAPH_SPACING = 60;
const ONTOLOGY_LAYER_BOX_PADDING = 10;
const SPACING_BETWEEN_BASE_NODE_AND_TREE_GRAPHS = 60;

const TreeVisContent: React.FunctionComponent<Props> = ({ treeGraphs }: Props) => {
    const [treeGraphBBoxes, setTreeGraphBBoxes] = React.useState<DOMRect[]>(treeGraphs.map(() => new DOMRect()));
    const [rootNodeBBoxes, setRootNodeBBoxes] = React.useState<DOMRect[]>(treeGraphs.map(() => new DOMRect()));
    const [baseNodeSize, setBaseNodeSize] = React.useState<Size>({ width: 0, height: 0 });

    // Reset the size estimates when the tree graphs change.
    React.useEffect(() => {
        setTreeGraphBBoxes(treeGraphs.map(() => new DOMRect()));
        setRootNodeBBoxes(treeGraphs.map(() => new DOMRect()));
    }, [treeGraphs]);

    // Memoize those handlers, so they do not lead to infinite re-renders
    // when doing the iterative bottom-up size estimation.
    const onGraphLayoutChangeHandlers = useMemo(
        () =>
            treeGraphs.map((g, idx) => (graphBBox: DOMRect, rootNodeBBox: DOMRect) => {
                setTreeGraphBBoxes((prev) => {
                    return produce(prev, (draftSizes) => {
                        draftSizes[idx] = graphBBox;
                    });
                });
                setRootNodeBBoxes((prev) => {
                    return produce(prev, (draftPositions) => {
                        draftPositions[idx] = rootNodeBBox;
                    });
                });
            }),
        [treeGraphs]
    );

    const onBaseNodeSizeChangeHandler = useCallback((newSize: Size) => {
        setBaseNodeSize(newSize);
    }, []);

    // Store the vertical offset for each graph, so that they can be stacked vertically.
    // Also, we need the onSizeChange handler for each graph.
    const treeGraphsWithAdditionalProperties = useMemo(() => {
        const verticalOffsets = treeGraphBBoxes
            .map((s) => s.height)
            .map((h, idx, arr) => idx * VERTICAL_GRAPH_SPACING + _.sum(arr.slice(0, idx)));

        return _.zip(treeGraphs, verticalOffsets, rootNodeBBoxes, onGraphLayoutChangeHandlers).filter((e) =>
            e.every((v) => v !== undefined)
        ) as [TreeGraph, number, DOMRect, GraphLayoutChangeHandler][]; // Check that all values are defined.
    }, [treeGraphBBoxes, onGraphLayoutChangeHandlers, rootNodeBBoxes, treeGraphs]);

    const rootNodesBBox = useMemo(() => {
        return getOverallBBox(rootNodeBBoxes);
    }, [rootNodeBBoxes]);

    const treeGraphsBBox = useMemo(() => {
        return getOverallBBox(treeGraphBBoxes);
    }, [treeGraphBBoxes]);

    // Place the base node in the middle of all root nodes.
    const rootNodesCenter = getBBoxCenter(rootNodesBBox);
    const maxRootNodeWidth = _.max(rootNodeBBoxes.map((r) => r.width)) ?? 0;

    const baseNodePosition = new DOMRect(
        0,
        rootNodesCenter.y - baseNodeSize.height / 2,
        baseNodeSize.width,
        baseNodeSize.height
    );

    return (
        <>
            <rect
                rx={10}
                fill={'var(--bs-gray)'}
                style={{ pointerEvents: 'none' }}
                fillOpacity={0.2}
                x={rootNodesBBox.x - ONTOLOGY_LAYER_BOX_PADDING}
                y={rootNodesBBox.y - ONTOLOGY_LAYER_BOX_PADDING}
                width={rootNodesBBox.width + 2 * ONTOLOGY_LAYER_BOX_PADDING}
                height={rootNodesBBox.height + 2 * ONTOLOGY_LAYER_BOX_PADDING}
            />
            {rootNodeBBoxes.map((rootNodePosition, idx) => (
                <BaseNodeEdge key={idx} baseNodePosition={baseNodePosition} rootNodePosition={rootNodePosition} />
            ))}
            {treeGraphsWithAdditionalProperties.map(([g, verticalOffset, rootNodePos, onLayoutChangeHandler], idx) => {
                return (
                    <TreeContextProvider key={g.graph.ontologyNodeHash} treeGraph={g}>
                        <TreeGraphComponent
                            x={
                                baseNodeSize.width +
                                maxRootNodeWidth / 2 +
                                SPACING_BETWEEN_BASE_NODE_AND_TREE_GRAPHS -
                                rootNodePos.width / 2
                            }
                            y={verticalOffset}
                            onLayoutChange={onLayoutChangeHandler}
                        />
                    </TreeContextProvider>
                );
            })}
            <BaseNode
                x={baseNodePosition.x}
                y={baseNodePosition.y}
                onSizeChange={onBaseNodeSizeChangeHandler}
                text={treeGraphs[0].graph.startingSequence}
            />
            {/*<WordListVis*/}
            {/*    x={treeGraphsBBox.x + treeGraphsBBox.width + SPACING_BETWEEN_BASE_NODE_AND_TREE_GRAPHS}*/}
            {/*    y={treeGraphsBBox.y}*/}
            {/*/>*/}
            <UpsetVis
                treeGraph={treeGraphs}
                x={baseNodePosition.x}
                y={treeGraphsBBox.y + treeGraphsBBox.height / 2}
                // x={baseNodePosition.x + 1620}
                // y={treeGraphsBBox.y + treeGraphsBBox.height + 260}
            ></UpsetVis>
        </>
    );
};

export default TreeVisContent;
