import { DependencyList, useContext, useEffect, useState } from 'react';
import ColorMapContext from 'App/ColorMapContext';
import { rgb, RGBColor } from 'd3-color';

export function useEmbeddingColor(
    embedding2d?: [number, number],
    defaultColor: RGBColor = rgb(0, 0, 0, 0),
    deps: DependencyList = []
): RGBColor {
    const { color2D } = useContext(ColorMapContext);
    const [embeddingColor, setEmbeddingColor] = useState<RGBColor>(defaultColor);

    useEffect(() => {
        const x = embedding2d ? embedding2d[0] : undefined;
        const y = embedding2d ? embedding2d[1] : undefined;

        if (x !== undefined && y !== undefined) {
            const maybeColor = color2D?.getColor(x, y);
            if (maybeColor) {
                setEmbeddingColor(rgb(maybeColor[0], maybeColor[1], maybeColor[2]));
            }
        }
    }, [...deps, color2D]); // eslint-disable-line react-hooks/exhaustive-deps

    return embeddingColor;
}
