export enum ColorMap {
    SPINNER = 'Custom',
    BREMM = 'Bremm',
    CUBEDIAGONAL = 'Cube Diagonal',
    SCHUMANN = 'Schumann',
    TEULING2 = 'Teuling 2',
    STEIGER = 'Steiger',
    ZIEGLER = 'Ziegler',
}

export const COLOR_MAPS = Object.values(ColorMap);
export type RGBColor = [number, number, number];
export type Range = [number, number];
