import React, { Fragment } from 'react';
import processString, { ProcessStringOption } from 'react-process-string-ts';

const SPECIAL_CHAR_MAPPING: Record<string, string> = {
    ' ': '\u2423',
    '\t': '\u2409',
    '\n': '\u240A',
};

/**
 * This function replaces non-printable characters with a styled JSX tspan element and wraps the text at max length.
 * @param input
 * @param x
 * @param y
 * @param maxLength
 * @param lineHeight
 * @param extraHighlightWords
 */
export function outlineSpecialCharsAndWrapText(
    input: string,
    x: number,
    y: number,
    maxLength?: number,
    lineHeight = 20,
    extraHighlightWords: string[] = []
): JSX.Element {
    const replaceSpecialCharOptions: ProcessStringOption[] = Object.entries(SPECIAL_CHAR_MAPPING).map(
        ([char, replacement]) => {
            // For each special character mapping, replace all occurrences of the character with a JSX span element
            // containing the replacement character.
            return {
                regex: new RegExp(char, 'gim'),
                fn: (key, result) => {
                    return (
                        <tspan
                            key={key}
                            style={{
                                fill: 'var(--bs-gray-500)',
                                dominantBaseline: 'alphabetic',
                                alignmentBaseline: 'baseline',
                            }}
                        >
                            {replacement}
                        </tspan>
                    );
                },
            };
        }
    );

    const maxLengthWrapperOptions: ProcessStringOption[] = [
        {
            // If the text is too long, replace the beginning with an ellipsis.
            regex: new RegExp(`(.{${maxLength},}?|.{1,${maxLength}}?$)(\\s|$)`, 'gim'),
            fn: (key, result) => {
                return (
                    <tspan key={key} x={x} y={key > 1 ? undefined : y} dy={key > 1 ? lineHeight : undefined}>
                        {result[0]}
                    </tspan>
                );
            },
        },
    ];

    const highlightWordOptions: ProcessStringOption[] = extraHighlightWords.map((word) => {
        return {
            regex: new RegExp(word, 'ig'),
            fn: (key, result) => {
                return (
                    <tspan
                        key={key}
                        style={{ fontWeight: 'bold', dominantBaseline: 'alphabetic', alignmentBaseline: 'baseline' }}
                    >
                        {result[0]}
                    </tspan>
                );
            },
        };
    });

    const allOptions = [...maxLengthWrapperOptions, ...highlightWordOptions, ...replaceSpecialCharOptions];

    return <>{processString(allOptions)(input)}</>;
}

/**
 * This function wraps text at max length.
 * @param input
 * @param maxLength
 */
export function wrapText(input: string, maxLength: number): JSX.Element {
    const maxLengthWrapperOptions: ProcessStringOption[] = [
        {
            // If the text is too long, replace the beginning with an ellipsis.
            regex: new RegExp(`(.{${maxLength},}?|.{1,${maxLength}}?$)(\\s|$)`, 'gim'),
            fn: (key, result) => {
                return (
                    <Fragment key={key}>
                        <span key={key}>{result[0]}</span>
                        <br />
                    </Fragment>
                );
            },
        },
    ];

    return <>{processString(maxLengthWrapperOptions)(input)}</>;
}

export function escapeHTMLEntities(input: string): JSX.Element {
    const replaceStringOptions: ProcessStringOption[] = [
        {
            // Replace newline characters with a <br/> element.
            regex: new RegExp('\n', 'gim'),
            fn: (key) => <Fragment key={key}>{SPECIAL_CHAR_MAPPING['\n']}</Fragment>,
        },
        {
            // Replace spaces with a non-breaking space.
            regex: new RegExp(` `, 'gim'),
            fn: (key) => <Fragment key={key}>&nbsp;</Fragment>,
        },
    ];

    const processed = processString(replaceStringOptions)(input);

    return <>{processed}</>;
}

export function findWordInString(str: string, word: string): { startPos: number; endPos: number }[] {
    const regex = new RegExp('\\b' + word + '\\b', 'ig');

    const matches = [];

    let match;
    while ((match = regex.exec(str)) !== null) {
        const startPos = match.index;
        const endPos = startPos + match[0].length;
        matches.push({ startPos, endPos });
    }

    return matches;
}
