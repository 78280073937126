import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo } from 'react';
import WordListContext from 'App/WordListContext';
import { groupBy3Key } from 'tools/helpers';
import { extractCombinations, UpSetJS, ISetCombinations, ISet, ISetLike } from '@upsetjs/react';
import { Row, UpsetDataObjectElement, UpsetListRootNode } from 'types/common/UpsetData';
import { TreeGraph, TreeGraphNode } from 'types/backend/response/TreeGraph';
import WordListHitsContext from 'App/WordListHitsContext';
import SetIntersection from 'App/TreeVis/UpsetVis/SetIntersection';
import { Size } from 'types/common/Size';
import { darker } from 'tools/colors';

interface Props {
    treeGraph: TreeGraph[];
    x: number;
    y: number;
}

const UpsetVis: FunctionComponent<Props> = ({ treeGraph, x, y }) => {
    const [maxIntersectionListHeight, setMaxIntersectionListHeight] = React.useState<number>(0);

    const { wordLists } = useContext(WordListContext);
    const { wordListHits } = useContext(WordListHitsContext);

    //Upset plot settings
    const [selection, setSelection] = React.useState<ISetLike<Row> | null>(null);
    const emptyExSetList: ISet<UpsetDataObjectElement>[] = [];
    const widthUpsetIntersect = 250; //150 für Column, 50 für offset
    const bandOffset = widthUpsetIntersect * 0.3; //75

    //Wordlist settings
    const activeWordLists = wordLists.filter((wordList) => wordList.active);
    //Get current onto nodes
    //Get all nodes of the tree, grouped  by ontology node
    const ontoTrees = treeGraph.filter((d) => d.graph.hasOwnProperty('ontologyNodeContent'));
    const wordDict: { [id: string]: UpsetDataObjectElement } = {};

    useEffect(() => {
        setMaxIntersectionListHeight(0);
    }, [wordLists]);
    const onIntersectionListHeightChange = useCallback((newSize: Size) => {
        setMaxIntersectionListHeight((prevState) => Math.max(prevState, newSize.height));
    }, []);

    //Loop over all wordlists
    for (let i = 0; i < wordListHits.length; i++) {
        const wordListHit = wordListHits[i];
        //For all words
        //group by ontonodes
        for (let j = 0; j < wordListHit.occurringWords.length; j++) {
            const occuringWord = wordListHit.occurringWords[j];
            const grouped: { treeGraph: TreeGraph; treeGraphNode: TreeGraphNode }[][] = groupBy3Key(
                occuringWord.nodes,
                'treeGraph',
                'graph',
                'ontologyNodeContent'
            );

            //Get all ontoNodes, and push them
            const combinedName = occuringWord.word + '( ' + wordListHit.wordList.name + ' )';
            if (combinedName in wordDict) {
                // wordDict[name].push(tree.graph.ontologyNodeContent)
            } else {
                wordDict[combinedName] = {
                    name: occuringWord.word,
                    sets: [],
                    value: wordListHit.wordList.name,
                    count: 0,
                };
            }
            wordDict[combinedName].count += occuringWord.nodes.length;
            const listMatches = Object.values(grouped).map((d) => d[0].treeGraph.graph.ontologyNodeContent);
            for (const match of listMatches) {
                if (match !== undefined && !wordDict[combinedName].sets.includes(match)) {
                    wordDict[combinedName].sets.push(match);
                }
            }
        }
    }

    for (let i = 0; i < ontoTrees.length; i++) {
        //if there is no element with ontoNode in wordDict, add it manually
        if (ontoTrees[i].graph.hasOwnProperty('ontologyNodeContent')) {
            const ontoName = ontoTrees[i].graph.ontologyNodeContent;
            if (ontoName !== undefined) {
                const test: string = ontoName;
                const hasElement = Object.values(wordDict).some((d) => d.sets.indexOf(test) !== -1);
                if (!hasElement) {
                    const emptyExSet: ISet<UpsetDataObjectElement> = {
                        type: 'set',
                        elems: [],
                        name: ontoName,
                        cardinality: 0,
                    };
                    emptyExSetList.push(emptyExSet);
                }
            }
        }
    }
    const wordDictArray = Object.values(wordDict);
    const { sets, combinations } = useMemo(
        () =>
            extractCombinations(wordDictArray, {
                type: 'distinctIntersection',
            }),
        [wordDictArray]
    );
    //Get words of the current lists that are not in any of the onto Nodes
    const emptyIntersectionWords = [];
    for (const list of activeWordLists) {
        for (const word of list.words) {
            if (wordDictArray.findIndex((d) => d.name === word) == -1) {
                emptyIntersectionWords.push({ name: word, sets: [], value: list.name });
            }
        }
    }
    const setsWithEmptySets: ISet<UpsetDataObjectElement>[] = [...emptyExSetList, ...sets];
    setsWithEmptySets.sort(
        (set1, set2) =>
            ontoTrees.findIndex((d) => d.graph.ontologyNodeContent === set2.name) -
            ontoTrees.findIndex((d) => d.graph.ontologyNodeContent === set1.name)
    );
    //Should sort this so that it matches the node order
    const combinationsIncEmpty: ISetCombinations = [
        ...combinations,
        {
            cardinality: emptyIntersectionWords.length,
            degree: 0,
            elems: emptyIntersectionWords, //[{name: 'beautiful', sets: [], value: 'beautiful'}],
            name: '{}',
            sets: new Set(),
            type: 'intersection',
        },
    ];
    const comb = useMemo(
        () =>
            [
                ...combinations,
                {
                    cardinality: 0,
                    degree: 2,
                    elems: [{ name: 'beautiful', sets: [], value: 'beautiful' }],
                    name: '{}',
                    sets: new Set(),
                    type: 'intersection',
                },
            ].map((d) => d.name),
        [combinations]
    );

    const height = ontoTrees.length * 50;

    const nodes2 = combinationsIncEmpty.map((d) => {
        const attrToConcepts: UpsetListRootNode[] = [];
        for (const list of activeWordLists) {
            const filtered = d.elems.filter((e) => e.value === list.name);
            let children: { label: string }[] = [];
            if (filtered.length !== 0) {
                children = filtered.map((e) => {
                    return { label: e.name };
                });
            }
            attrToConcepts.push({
                color: darker(list.color),
                label: list.name,
                symbol: list.symbol,
                children: children,
                countChildren: d.elems.map((d) => d.count),
            });
        }
        return attrToConcepts;
    });

    const overallWidth = (comb.length + 1) * widthUpsetIntersect;
    return (
        <>
            {activeWordLists.length > 0 && (
                <g transform={`translate(${x - overallWidth - 80}, ${y - (height + maxIntersectionListHeight) / 2})`}>
                    <UpSetJS
                        color={'var(--bs-gray-700)'}
                        notMemberColor={'var(--bs-gray-400)'}
                        alternatingBackgroundColor={'var(--bs-gray-200)'}
                        textColor={'var(--bs-gray-900)'}
                        exportButtons={false}
                        sets={setsWithEmptySets}
                        combinations={combinationsIncEmpty}
                        width={overallWidth}
                        height={height}
                        widthRatios={[0, widthUpsetIntersect]} //widthRatios={[0, 140]}
                        heightRatios={[0]}
                        // selection={selection}
                        onHover={setSelection}
                        selectionColor={'black'}
                        barPadding={0.0}
                        padding={0}
                        // setAddonPadding={0}
                        // emptySelection={true}
                    />
                    <g transform={`translate(0, 0)`}>
                        {comb.map((n, idx) => (
                            <SetIntersection
                                y={height}
                                key={idx}
                                setName={n}
                                nodes2={nodes2}
                                idx={idx}
                                widthUpsetIntersect={widthUpsetIntersect}
                                selection={selection}
                                bandOffset={bandOffset}
                                onSizeChange={onIntersectionListHeightChange}
                            />
                        ))}
                    </g>
                </g>
            )}
        </>
    );
};

export default UpsetVis;
