/*
 * This is an adaption of https://github.com/dominikjaeckle/Color2D
 */

import { ColorMap, Range, RGBColor } from './types';

import {
    BASE64_SPINNER,
    BASE64_BREMM,
    BASE64_CUBEDIAGONAL,
    BASE64_SCHUMANN,
    BASE64_STEIGER,
    BASE64_TEULING2,
    BASE64_ZIEGLER,
} from './data';

const COLOR_MAP_SIZE = 512;
const DEFAULT_RANGE: Range = [0, 1];

/**
 * Clamps the given value to the given range.
 * @param value
 * @param range
 * @private
 */
function clamp(value: number, range: Range): number {
    return Math.min(Math.max(value, range[0]), range[1]);
}

/**
 * Linearly scales the given value from the source range to the target range.
 * @param value
 * @param sourceRange
 * @param targetRange
 */
function linearlyScaleValue(value: number, sourceRange: Range, targetRange: Range): number {
    return (
        ((value - sourceRange[0]) * (targetRange[1] - targetRange[0])) / (sourceRange[1] - sourceRange[0]) +
        targetRange[0]
    );
}

class Color2D {
    private constructor(
        private rangeX: Range,
        private rangeY: Range,
        private cmapPngBase64: string,
        private pixelData: RGBColor[][]
    ) {}

    /**
     * Returns a Color2D instance with the specified color map loaded.
     * @param colorMap The color map to use.
     * @param rangeX The x input value range. Default: [0, 1].
     * @param rangeY The y input value range. Default: [0, 1].
     */
    public static async getInstance(
        colorMap: ColorMap,
        rangeX: Range = DEFAULT_RANGE,
        rangeY: Range = DEFAULT_RANGE
    ): Promise<Color2D> {
        let cmapPngBase64: string;
        switch (colorMap) {
            case ColorMap.SPINNER:
                cmapPngBase64 = BASE64_SPINNER;
                break;
            case ColorMap.CUBEDIAGONAL:
                cmapPngBase64 = BASE64_CUBEDIAGONAL;
                break;
            case ColorMap.SCHUMANN:
                cmapPngBase64 = BASE64_SCHUMANN;
                break;
            case ColorMap.STEIGER:
                cmapPngBase64 = BASE64_STEIGER;
                break;
            case ColorMap.TEULING2:
                cmapPngBase64 = BASE64_TEULING2;
                break;
            case ColorMap.ZIEGLER:
                cmapPngBase64 = BASE64_ZIEGLER;
                break;
            case ColorMap.BREMM:
            default:
                cmapPngBase64 = BASE64_BREMM;
                break;
        }
        const pixelData = await Color2D.init(cmapPngBase64);
        return new Color2D(rangeX, rangeY, cmapPngBase64, pixelData);
    }

    /**
     * Returns the pixel data of the given Base64-encoded image.
     * @param cmapPngBase64
     * @return A promise resolving to the pixel data of the given image.
     * @private
     */
    private static init(cmapPngBase64: string): Promise<RGBColor[][]> {
        const img = new Image();

        const onLoadPromise = new Promise<RGBColor[][]>((resolve, reject) => {
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = COLOR_MAP_SIZE;
                canvas.height = COLOR_MAP_SIZE;
                const ctx = canvas.getContext('2d');

                if (ctx) {
                    ctx.drawImage(img, 0, 0);
                    const imageData = ctx.getImageData(0, 0, COLOR_MAP_SIZE, COLOR_MAP_SIZE);

                    const pixelData: RGBColor[][] = [];

                    // Convert the image data to a 2D array of RGB colors.
                    for (let x = 0; x < COLOR_MAP_SIZE; x++) {
                        pixelData[x] = [];
                        for (let y = 0; y < COLOR_MAP_SIZE; y++) {
                            const offset = (y * COLOR_MAP_SIZE + x) * 4;
                            pixelData[x][y] = [
                                imageData.data[offset],
                                imageData.data[offset + 1],
                                imageData.data[offset + 2],
                            ];
                        }
                    }
                    resolve(pixelData);
                } else {
                    reject('Could not create canvas 2D context.');
                }
            };
        });

        img.src = 'data:image/png;base64,' + cmapPngBase64;
        return onLoadPromise;
    }

    /**
     * Returns the color of the color map at the given (scaled) position.
     * @param x The x position to sample at.
     * @param y The y position to sample at.
     * @return The color at the specified position on the color map.
     */
    public getColor(x: number, y: number): RGBColor {
        const imageX = Math.floor(clamp(Math.round(this.scaleX(x)), [0, COLOR_MAP_SIZE - 1]));
        const imageY = Math.floor(clamp(Math.round(this.scaleY(y)), [0, COLOR_MAP_SIZE - 1]));

        return this.pixelData[imageX][imageY];
    }

    /**
     * Get the Base64-encoded PNG image of the loaded color map.
     * @return A string containing the Base64-encoded PNG image data.
     */
    public getBase64EncodedPngImageData(): string {
        return this.cmapPngBase64;
    }

    /**
     * Scales the given x value to the target range.
     * @param x An x value to scale.
     * @param targetRange The target range to scale to. Default: [0, 1].
     * @return The scaled x value.
     */
    public toTargetRangeX(x: number, targetRange: Range = DEFAULT_RANGE): number {
        return linearlyScaleValue(x, this.rangeX, targetRange);
    }

    /**
     * Scales the given y value to the target range.
     * @param y A y value to scale.
     * @param targetRange The target range to scale to. Default: [0, 1].
     * @return The scaled y value.
     */
    public toTargetRangeY(y: number, targetRange: Range = DEFAULT_RANGE): number {
        return linearlyScaleValue(y, this.rangeY, targetRange);
    }

    /**
     * Scales the given x-Coordinate from the source x-range to the
     * target x-range.
     * @param x The x value to scale.
     * @return The scaled x value.
     * @private
     */
    private scaleX(x: number): number {
        return linearlyScaleValue(x, this.rangeX, [0, COLOR_MAP_SIZE - 1]);
    }

    /**
     * Scales the given y-Coordinate from the source y-range to the
     * target y-range.
     * @param y The y value to scale.
     * @return The scaled y value.
     * @private
     */
    private scaleY(y: number): number {
        return linearlyScaleValue(y, this.rangeY, [0, COLOR_MAP_SIZE - 1]);
    }
}

export default Color2D;
