import React, { FunctionComponent, useContext, useMemo } from 'react';
import styled from 'styled-components';
import PredictionParametersContextProvider from 'App/PredictionParametersContextProvider';
import TreeVis from 'App/TreeVis/TreeVis';
import TreeConfigContextProvider from 'App/TreeConfigContextProvider';
import TreeConfigContext from 'App/TreeConfigContext';
import { Accordion, Card } from 'react-bootstrap';
import { IoMdMap } from 'react-icons/io';
import { IoGitNetworkSharp } from 'react-icons/io5';
import { MdOutlineSettingsInputComponent } from 'react-icons/md';
import EmbeddingMapVis from 'App/EmbeddingMapVis/EmbeddingMapVis';
import ColorMapContextProvider from 'App/ColorMapContextProvider';
import TreeSelectionPanel from 'App/ConfigPanels/TreeSelectionPanel';
import PredictionParametersPanel from 'App/ConfigPanels/PredictionParametersPanel';
import TreeContextProvider from './TreeContextProvider';
import PredictionParametersContext from './PredictionParametersContext';
import NodeHighlightContextProvider from './NodeHighlightContextProvider';
import { TreeGraph } from 'types/backend/response/TreeGraph';
import TreeDisplayStyleContextProvider from 'App/TreeDisplayStyleContextProvider';
import TreeDisplayStyleToggle from 'App/ConfigPanels/TreeDisplayStyleToggle';
import WordListPanel from 'App/ConfigPanels/WordListPanel';
import WordListContextProvider from 'App/WordListContextProvider';
import { WORD_LISTS } from 'data/wordlists';
import WordListHitsContextProvider from 'App/WordListHitsContextProvider';
import WordListContext from 'App/WordListContext';
import TemplateInstanceSelectionPanel from 'App/ConfigPanels/TemplateInstanceSelectionPanel';
import { BsList } from 'react-icons/bs';
import BackendQueryEngine from 'backend/BackendQueryEngine';

const AppContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
`;

const PanelsContainerBase = styled.div`
    position: absolute;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > * {
        margin-bottom: 20px;
    }

    > :last-child {
        margin-bottom: 0;
    }
`;

const LeftPanelsContainer = styled(PanelsContainerBase)`
    top: 20px;
    left: 20px;
    align-items: flex-start;
`;

const RightPanelsContainer = styled(PanelsContainerBase)`
    top: 20px;
    right: 20px;
    align-items: flex-end;
`;

const PanelAccordion = styled(Accordion)`
    min-width: 250px;
    position: static;
`;

const VerticalContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    & > * {
        //border-bottom: 1px solid var(--bs-gray);
        flex-grow: 1;
    }
`;

const ModelIndicator = styled.div`
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    font-family: 'Roboto Light', 'Ubuntu Light', 'Ubuntu', monospace;
    font-weight: bold;
`;

function App() {
    return (
        <AppContainer>
            <PredictionParametersContextProvider>
                <AppPredictionParametersContext />
            </PredictionParametersContextProvider>
            <ModelIndicatorComponent />
        </AppContainer>
    );
}

function ModelIndicatorComponent() {
    const [modelName, setModelName] = React.useState<string>();

    React.useEffect(() => {
        BackendQueryEngine.getModelName().then((modelName) => {
            setModelName(modelName);
        });
    }, []);

    return <ModelIndicator>{modelName}</ModelIndicator>;
}

function AppPredictionParametersContext() {
    const { topK, nWords } = useContext(PredictionParametersContext);

    return (
        <TreeConfigContextProvider topK={topK} nWords={nWords}>
            <TreeDisplayStyleContextProvider>
                <ColorMapContextProvider>
                    <WordListContextProvider allWordLists={WORD_LISTS}>
                        <AppWithTreeStateContext />
                    </WordListContextProvider>
                </ColorMapContextProvider>
            </TreeDisplayStyleContextProvider>
        </TreeConfigContextProvider>
    );
}

const AppWithTreeStateContext: FunctionComponent = () => {
    const { optionalTreeGraphs } = React.useContext(TreeConfigContext);

    return (
        <>
            <LeftPanelsContainer>
                <PanelAccordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Tree Selection&nbsp;
                            <IoGitNetworkSharp transform={'rotate(90)'} />
                        </Accordion.Header>
                        <Accordion.Body>
                            <TreeSelectionPanel />
                        </Accordion.Body>
                    </Accordion.Item>
                </PanelAccordion>
                <PanelAccordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Prediction Parameters&nbsp;
                            <MdOutlineSettingsInputComponent />
                        </Accordion.Header>
                        <Accordion.Body>
                            <PredictionParametersPanel />
                        </Accordion.Body>
                    </Accordion.Item>
                </PanelAccordion>
                <Card>
                    <Card.Body className={'no-select'} style={{ whiteSpace: 'nowrap', padding: '12px 20px 12px 20px' }}>
                        <TemplateInstanceSelectionPanel />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body className={'no-select'} style={{ whiteSpace: 'nowrap', padding: '12px 20px 12px 20px' }}>
                        <TreeDisplayStyleToggle />
                    </Card.Body>
                </Card>
            </LeftPanelsContainer>

            {optionalTreeGraphs && optionalTreeGraphs.length > 0 && <AppWithTrees treeGraphs={optionalTreeGraphs} />}
        </>
    );
};

interface AppWithTreesProps {
    treeGraphs: TreeGraph[];
}

const AppWithTrees: FunctionComponent<AppWithTreesProps> = ({ treeGraphs }) => {
    const { activeWordLists } = useContext(WordListContext);

    // Make tree graphs unique by appending the ontology node hash to tree- and node IDs.
    const uniqueTreeGraphs: TreeGraph[] = useMemo(() => {
        return treeGraphs.map((g) => {
            const uniquify = (value: string) => value.concat('_', g.graph.ontologyNodeHash ?? 'no-hash');

            return {
                ...g,
                graph: {
                    ...g.graph,
                    treeId: uniquify(g.graph.treeId),
                    rootNodeId: uniquify(g.graph.rootNodeId),
                },
                nodes: g.nodes.map((n) => ({
                    ...n,
                    id: uniquify(n.id),
                    keywords: n.keywords.map((k) => ({
                        ...k,
                        leafNodeId: uniquify(k.leafNodeId),
                    })),
                })),
                links: g.links.map((l) => ({
                    ...l,
                    source: uniquify(l.source),
                    target: uniquify(l.target),
                })),
            };
        });
    }, [treeGraphs]);

    console.info('Uniquified tree graphs: ', uniqueTreeGraphs);

    const jointTreeGraph: TreeGraph = useMemo(() => {
        return {
            ...treeGraphs[0],
            graph: {
                ...treeGraphs[0].graph,
                ontologyNodeHash: undefined,
            },
            nodes: uniqueTreeGraphs.flatMap((g) => g.nodes),
            links: uniqueTreeGraphs.flatMap((g) => g.links),
        };
    }, [treeGraphs, uniqueTreeGraphs]);

    return (
        <>
            <NodeHighlightContextProvider>
                <RightPanelsContainer>
                    <PanelAccordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                2D Embedding Map&nbsp;
                                <IoMdMap />
                            </Accordion.Header>
                            <Accordion.Body>
                                <TreeContextProvider treeGraph={jointTreeGraph}>
                                    <EmbeddingMapVis />
                                </TreeContextProvider>
                            </Accordion.Body>
                        </Accordion.Item>
                    </PanelAccordion>
                    <PanelAccordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                Word Lists&ensp;
                                <BsList />
                            </Accordion.Header>
                            <Accordion.Body>
                                <WordListPanel />
                            </Accordion.Body>
                        </Accordion.Item>
                    </PanelAccordion>
                </RightPanelsContainer>

                <VerticalContainer>
                    <WordListHitsContextProvider treeGraphs={uniqueTreeGraphs} activeWordLists={activeWordLists}>
                        <TreeVis treeGraphs={uniqueTreeGraphs} />
                    </WordListHitsContextProvider>
                </VerticalContainer>
            </NodeHighlightContextProvider>
        </>
    );
};

export default App;
