import React, { FunctionComponent } from 'react';
import WordListGroup from 'App/TreeVis/UpsetVis/WordListGroup';
import { UpsetListRootNode } from 'types/common/UpsetData';
import { Card, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { ISetLike } from '@upsetjs/react';
import { PropsWithSizeUpdater } from 'types/PropsWithSizeUpdater';
import { useMeasure } from 'react-use';

const StyledForeignObject = styled.foreignObject`
    // Disable all pointer events on the foreign object, but not on the children.
    pointer-events: none;
    & > * {
        pointer-events: all;
    }
`;

const StyledCardTitle = styled(Card.Title)`
    // Prevent all line wrapping.
    //white-space: nowrap;
    font-size: 16px;
    margin: 8px 20px;
    text-align: center;
    font-weight: bold;
    color: var(--bs-gray-900);
`;

interface Props {
    setName: string;
    nodes2: UpsetListRootNode[][];
    idx: number;
    widthUpsetIntersect: number;
    bandOffset: number;
    y: number;
    selection: ISetLike | null;
}

const SetIntersection: FunctionComponent<PropsWithSizeUpdater<Props>> = ({
    setName,
    nodes2,
    idx,
    widthUpsetIntersect,
    bandOffset,
    y,
    selection,
    onSizeChange,
}) => {
    const [ref, size] = useMeasure<HTMLDivElement>();

    React.useEffect(() => {
        onSizeChange?.(size);
    }, [onSizeChange, size]);

    const offset = 20;
    const offsetX = widthUpsetIntersect * idx + offset / 2;
    const currentNodes = nodes2[idx];

    return (
        <g transform={`translate(${offsetX + widthUpsetIntersect}, ${y})`}>
            <StyledForeignObject width={widthUpsetIntersect - offset} height="800">
                <Card
                    ref={ref}
                    className={`${
                        selection &&
                        ((selection.type === 'set' && setName.includes(selection.name)) ||
                            (selection.type === 'distinctIntersection' && setName == selection.name))
                            ? 'isHovered'
                            : ''
                    }`}
                >
                    <StyledCardTitle>{setName}</StyledCardTitle>
                    <ListGroup variant="flush">
                        {currentNodes.map((n, idx) => (
                            <WordListGroup
                                node={n}
                                widthUpsetIntersect={widthUpsetIntersect}
                                initialCollapsed={setName === '{}'}
                                key={idx}
                            />
                        ))}
                    </ListGroup>
                </Card>
            </StyledForeignObject>
        </g>
    );
};

export default SetIntersection;
