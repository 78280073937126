import React, { useEffect } from 'react';
import ColorMapContext from './ColorMapContext';
import { COLOR_MAPS, ColorMap } from 'lib/color2d/types';
import Color2D from 'lib/color2d/color2d';

const ColorMapContextProvider = ({ children }: { children: React.ReactNode }) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats
    const [colorMap, setColorMap] = React.useState<ColorMap>(COLOR_MAPS[0]);
    const [color2D, setColor2D] = React.useState<Color2D | undefined>();

    useEffect(() => {
        // Note: the range passed to getInstance is dependent on the anchored keyword projection.
        Color2D.getInstance(colorMap, [-3, 3], [-3, 3]).then((color2D) => {
            setColor2D(color2D);
        });
    }, [colorMap]);

    return (
        <ColorMapContext.Provider
            value={{
                colorMap,
                color2D,
                setColorMap,
            }}
        >
            {children}
        </ColorMapContext.Provider>
    );
};

export default ColorMapContextProvider;
