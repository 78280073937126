import React, { FunctionComponent } from 'react';
import { ListGroup } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { UpsetListNode } from 'types/common/UpsetData';
import styled from 'styled-components';

const StyledListGroupItem = styled(ListGroup.Item)`
    // Prevent all line wrapping.
    white-space: nowrap;
    padding: 4px;
`;

interface Props {
    node: UpsetListNode;
}

const WordListElement: FunctionComponent<Props> = ({ node }) => {
    return (
        <StyledListGroupItem
            style={{ color: node.color }}
            as="li"
            className="d-flex justify-content-between align-items-center"
        >
            <div className="ms-2 me-auto">{node.label}</div>
            <Badge bg="secondary" pill>
                {node.count}
            </Badge>
        </StyledListGroupItem>
    );
};

export default WordListElement;
