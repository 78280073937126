import React, { FunctionComponent, PropsWithChildren, useCallback, useMemo } from 'react';
import WordListHitsContext, { WordListHit } from 'App/WordListHitsContext';
import { StatefulWordList } from 'App/WordListContext';
import { TreeGraph } from 'types/backend/response/TreeGraph';
import { findWordInString } from 'tools/text-processing';
import { doIntervalsOverlap } from 'tools/helpers';

interface Props {
    activeWordLists: StatefulWordList[];
    treeGraphs: TreeGraph[];
}

const WordListHitsContextProvider: FunctionComponent<PropsWithChildren<Props>> = ({
    activeWordLists,
    treeGraphs,
    children,
}) => {
    const wordListHits = useMemo(() => {
        const tmpWordListHits: WordListHit[] = [];

        treeGraphs.forEach((treeGraph) => {
            treeGraph.nodes.forEach((treeGraphNode) => {
                activeWordLists.forEach((wordList) => {
                    wordList.words.forEach((word: string) => {
                        // Check, whether treeGraphNode.beamSequence contains the word.
                        const matches = findWordInString(treeGraphNode.beamSequence, word);

                        matches.forEach(({ startPos, endPos }) => {
                            // If startPos and endPos overlap with treeGraphNode.startPos and treeGraphNode.endPos,
                            // draw a symbol at the position of the word.
                            if (
                                doIntervalsOverlap(
                                    [startPos, endPos - 1],
                                    [treeGraphNode.startPos, treeGraphNode.endPos]
                                )
                            ) {
                                // Add the word to the list of hits for this word list.
                                // If the word list is not yet in the list of hits, add it.
                                let wordListHit = tmpWordListHits.find((wlh) => wlh.wordList.id === wordList.id);
                                if (wordListHit === undefined) {
                                    tmpWordListHits.push({ wordList, occurringWords: [] });
                                    wordListHit = tmpWordListHits[tmpWordListHits.length - 1];
                                }

                                const occurringWord = wordListHit.occurringWords.find((ow) => ow.word === word);

                                if (occurringWord) {
                                    occurringWord.nodes.push({
                                        treeGraph,
                                        treeGraphNode,
                                    });
                                } else {
                                    wordListHit.occurringWords.push({
                                        word,
                                        nodes: [
                                            {
                                                treeGraph,
                                                treeGraphNode,
                                            },
                                        ],
                                    });
                                }
                            }
                        });
                    });
                });
            });
        });

        return tmpWordListHits;
    }, [activeWordLists, treeGraphs]);

    const getWordListsInNode = useCallback(
        (nodeId: string): StatefulWordList[] => {
            const wordListHitsInNode = wordListHits.filter((wordListHit) =>
                wordListHit.occurringWords.some((occurringWord) =>
                    occurringWord.nodes.some((node) => node.treeGraphNode.id === nodeId)
                )
            );

            return wordListHitsInNode.map((wordListHit) => wordListHit.wordList);
        },
        [wordListHits]
    );

    return (
        <WordListHitsContext.Provider value={{ wordListHits, getWordListsInNode }}>
            {children}
        </WordListHitsContext.Provider>
    );
};

export default WordListHitsContextProvider;
